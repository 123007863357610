// @app/modules/duck/operations.jsx
import _ from 'lodash';
import { getDataRefs } from './api';

const fetchDataRefs = (moduleIdent, refIdent) => {
    return dispatch => {
        return getDataRefs(dispatch, moduleIdent, refIdent);
    };
};

export default { fetchDataRefs };
