import { useEffect, useContext } from 'react';
import { SignalRContext } from '../../common/SignalR';

const defaultParams = {};

export function useSignalR(onReceived, params = defaultParams) {
    const connection = useContext(SignalRContext);
    useEffect(() => {
        if (connection) {
            connection.addSubscription('MsgApiNotification', 'MsgApiNotification');
        } else {
            console.error('ConnectionContext returned falsey value');
        }

        return () => connection.removeSubscription('MsgApiNotification');
    }, [connection, params]);

    useEffect(() => {
        if (connection) {
            connection.setOnReceived('MsgApiNotification', onReceived);
        } else {
            console.error('ConnectionContext returned falsey value');
        }
    }, [connection, onReceived]);
}
