import _ from 'lodash';

export const validateAuthMicrosoft = (ident, code) => {
    return new Promise(function (resolve, reject) {
        return fetch(`api/Auth/ValidateAuthMicrosoft?ident=${ident}${code ? `&code=${code}` : ''}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                return null;
            })
            .then(result => {
                return resolve(result);
            })
            .catch(error => {
                return reject(error.message);
            });
    });
};

export const loginMicrosoft = (config, ident) => {
    return new Promise(function (resolve, reject) {
        if (ident) {
            const url = `${config.authority}/oauth2/v2.0/authorize?client_id=${config.clientId}&response_type=${
                config.responseType
            }&redirect_uri=${window.location.href.split('?')[0]}&scope=${config.scope}&login_hint=${ident}`;
            window.location = url;
            return resolve();
        }
        return reject('Ident is missing');
    });
};
