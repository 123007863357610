// @app/components/MyPage/duck/reducers.js
import { createReducer } from 'reduxsauce';
import _ from 'lodash';
import { Types } from './actions';

export const INITIAL_STATE = {
    myProfile: null,
    isLoadingMyProfile: false,
    isSavingUserAvatarImage: false,
    loginLog: [],
    isLoadingLoginLog: true,
    isLoadingMoreLoginLog: false,
};

export const requestMyProfile = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingMyProfile: true };
};

export const receiveMyProfile = (state = INITIAL_STATE, action) => {
    const { profile } = action;
    return { ...state, myProfile: profile, isLoadingMyProfile: false };
};

export const requestSaveUserAvatarImage = (state = INITIAL_STATE, action) => {
    return { ...state, isSavingUserAvatarImage: true };
};

export const receiveSaveUserAvatarImage = (state = INITIAL_STATE, action) => {
    const { result } = action;
    const { myProfile } = state;

    let avatar = _.find(_.get(state, 'myProfile.contactInfo'), c => {
        return _.toLower(c.name) === 'avatar' && _.toLower(c.type) === 'image';
    });
    avatar.value = result.path;
    return {
        ...state,
        isSavingUserAvatarImage: false,
        myProfile: {
            ...myProfile,
            contactInfo: [
                ..._.filter(myProfile.contactInfo, i => {
                    return i.name !== 'avatar';
                }),
                avatar,
            ],
        },
    };
};

export const updateProfileContactInfo = (state = INITIAL_STATE, action) => {
    const { contactInfo } = action;

    const newArray = _.map(_.get(state, 'myProfile.contactInfo'), item => {
        if (item.guid !== contactInfo.guid) {
            return item;
        }
        return { ...item, ...contactInfo };
    });

    return {
        ...state,
        myProfile: {
            ...state.profile,
            contactInfo: newArray,
        },
    };
};

export const requestLoginLog = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingLoginLog: true };
};

export const receiveLoginLog = (state = INITIAL_STATE, action) => {
    const { loginLog } = action;
    return { ...state, loginLog: loginLog, isLoadingLoginLog: false };
};

export const requestMoreLoginLog = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingMoreloginLog: true,
    };
};

export const receiveMoreLoginLog = (state = INITIAL_STATE, action) => {
    const { loginLog } = action;
    return {
        ...state,
        loginLog: [...state.loginLog, ...loginLog],
        isLoadingMoreloginLog: false,
    };
};

export const HANDLERS = {
    [Types.REQUEST_MY_PROFILE]: requestMyProfile,
    [Types.RECEIVE_MY_PROFILE]: receiveMyProfile,
    [Types.REQUEST_SAVE_USER_AVATAR_IMAGE]: requestSaveUserAvatarImage,
    [Types.RECEIVE_SAVE_USER_AVATAR_IMAGE]: receiveSaveUserAvatarImage,
    [Types.MY_PAGE_UPDATE_PROFILE_CONTACT_INFO]: updateProfileContactInfo,
    [Types.REQUEST_LOGIN_LOG]: requestLoginLog,
    [Types.RECEIVE_LOGIN_LOG]: receiveLoginLog,
    [Types.REQUEST_MORE_LOGIN_LOG]: requestMoreLoginLog,
    [Types.RECEIVE_MORE_LOGIN_LOG]: receiveMoreLoginLog,
};

export default createReducer(INITIAL_STATE, HANDLERS);
