// duck/auth/operations.js
import { Creators } from './actions';
import _ from 'lodash';
import { getPrivacyPolicy } from './api';

const requestPrivacyPolicyAction = Creators.privacyRequestPrivacyPolicy;
const receivePrivacyPolicyAction = Creators.privacyReceivePrivacyPolicy;

const requestPrivacyPolicy = (page, pageSize) => {
    return dispatch => {
        dispatch(requestPrivacyPolicyAction());
        getPrivacyPolicy(dispatch, page, pageSize).then(result => {
            dispatch(receivePrivacyPolicyAction(result));
        });
    };
};

export default { requestPrivacyPolicy };
