import React from 'react';
//import _ from 'lodash';
//import './general.css';

function QAFieldComponent({ style, children }) {
    const defaultStyle = {
        backgroundColor: 'var(--primary-on-color)',
        padding: '1.5em',
        borderRadius: '4px',
        marginLeft: '1.5em',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1.5em',
    };

    const actualStyle = style ? { ...defaultStyle, ...style } : defaultStyle;

    return <span style={actualStyle}>{children}</span>;
}

export default QAFieldComponent;
