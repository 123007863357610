// @app/duck/actions.jsx
import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    requestAuthIdent: [],
    receiveAuthIdent: ['authIdent'],
    loginStart: ['auth'],
    loginCanceled: [],
    loginSuccess: ['token'],
    loginFailed: ['auth'],
    logoutStart: [],
    logoutSuccess: [],
    logoutFailed: [],
    wasNotFound: [],
    validateAuth: ['auth'],
    validateAuthStart: [],
    validateAuthSuccess: [],
    validateAuthFailed: [],
});

export { Creators, Types };
