import React from 'react';
import { Icon, Header } from 'semantic-ui-react';
import '../phrase.css';

class PhraseParagraphComponent extends React.Component {
    render() {
        const { text, className, showEdit, maxWidth, color, onClick, id, style } = this.props;
        return (
            <p
                color={color}
                className={className ? `phrase-paragraph ${className}` : 'phrase-paragraph'}
                onClick={onClick}
                id={id}
                style={{ ...style, maxWidth: maxWidth }}
            >
                {showEdit && this.renderEditIcon()}

                {text}
            </p>
        );
    }

    renderIcon = name => {
        return <Icon name={name} />;
    };

    renderDescription = () => {
        const { description } = this.props;
        return <Header.Subheader>{description}</Header.Subheader>;
    };

    renderLabel = () => {
        const { label, showEdit } = this.props;
        return (
            <label>
                {label} {showEdit && this.renderEditIcon()}
            </label>
        );
    };

    renderEditIcon = () => {
        return <Icon name="edit" className="phrase-edit-icon right" onClick={this.props.handleOpenEdit} />;
    };
}

export default PhraseParagraphComponent;
