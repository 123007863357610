// duck/auth/operations.js
import { Creators } from './actions';
import _ from 'lodash';
import { validateAuthMicrosoft, loginMicrosoft } from './authMicrosoft';
import { validateAuthVeniCloud, loginVeniCloud } from './authVeniCloud';
import { validateAuthProdReg, loginProdReg } from './authProdReg';
import { getAuthIdent, logout, getMicrosoftConfig, getProdRegConfig } from './api';

const requestAuthIdentAction = Creators.requestAuthIdent;
const receiveAuthIdentAction = Creators.receiveAuthIdent;
const loginStartAction = Creators.loginStart;
const loginCanceledAction = Creators.loginCanceled;
const loginSuccessAction = Creators.loginSuccess;
const loginFailedAction = Creators.loginFailed;
const logoutStartAction = Creators.logoutStart;
const logoutSuccessAction = Creators.logoutSuccess;
const logoutFailedAction = Creators.logoutFailed;
const validateAuthStartAction = Creators.validateAuthStart;
const validateAuthSuccessAction = Creators.validateAuthSuccess;
const validateAuthFailedAction = Creators.validateAuthFailed;
const wasNotFoundAction = Creators.wasNotFound;

const fetchAuthIdent = (ident, isPersistent) => {
    return dispatch => {
        dispatch(requestAuthIdentAction(ident));
        getAuthIdent(dispatch, ident, isPersistent).then(result => {
            if (result && !result.isError) {
                dispatch(receiveAuthIdentAction(result));
            } else {
                dispatch(receiveAuthIdentAction(null));
            }
        });
    };
};

const doValidateAuth = (authIdent, code) => {
    return dispatch => {
        dispatch(validateAuthStartAction());
        switch (_.toLower(authIdent.type)) {
            case 'venicloud': {
                validateAuthVeniCloud(authIdent.ident)
                    .then(loginResult => {
                        if (loginResult && !loginResult.isError) {
                            dispatch(validateAuthSuccessAction());
                        } else {
                            dispatch(validateAuthFailedAction());
                            dispatch(logoutStartAction());
                            logout(dispatch).then(result => {
                                if (result && !result.isError) {
                                    dispatch(logoutSuccessAction());
                                } else {
                                    dispatch(logoutFailedAction());
                                }
                            });
                        }
                    })
                    .catch(() => {
                        dispatch(validateAuthFailedAction());
                        dispatch(logoutStartAction());
                        logout(dispatch).then(result => {
                            if (result && !result.isError) {
                                dispatch(logoutSuccessAction());
                            } else {
                                dispatch(logoutFailedAction());
                            }
                        });
                    });
                break;
            }
            case 'microsoft': {
                validateAuthMicrosoft(authIdent.ident, code)
                    .then(loginResult => {
                        if (loginResult && !loginResult.isError) {
                            dispatch(validateAuthSuccessAction());
                        } else {
                            dispatch(validateAuthFailedAction());
                            dispatch(logoutStartAction());
                            logout(dispatch).then(result => {
                                if (result && !result.isError) {
                                    dispatch(logoutSuccessAction());
                                } else {
                                    dispatch(logoutFailedAction());
                                }
                            });
                        }
                    })
                    .catch(() => {
                        dispatch(validateAuthFailedAction());
                        dispatch(logoutStartAction());
                        logout(dispatch).then(result => {
                            if (result && !result.isError) {
                                dispatch(logoutSuccessAction());
                            } else {
                                dispatch(logoutFailedAction());
                            }
                        });
                    });
                break;
            }
            case 'prodreg': {
                validateAuthProdReg(authIdent.ident, code)
                    .then(loginResult => {
                        if (loginResult && !loginResult.isError) {
                            dispatch(validateAuthSuccessAction());
                        } else {
                            dispatch(validateAuthFailedAction());
                            dispatch(logoutStartAction());
                            dispatch(wasNotFoundAction());
                            logout(dispatch).then(result => {
                                if (result && !result.isError) {
                                    dispatch(logoutSuccessAction());
                                } else {
                                    dispatch(logoutFailedAction());
                                }
                            });
                        }
                    })
                    .catch(() => {
                        dispatch(validateAuthFailedAction());
                        dispatch(logoutStartAction());
                        logout(dispatch).then(result => {
                            if (result && !result.isError) {
                                dispatch(logoutSuccessAction());
                            } else {
                                dispatch(logoutFailedAction());
                            }
                        });
                    });
                break;
            }
            default: {
                console.error(`${authIdent.type} is not a valid authenticator!`);
                dispatch(validateAuthFailedAction());
                dispatch(logoutStartAction());
                logout(dispatch).then(result => {
                    if (result && !result.isError) {
                        dispatch(logoutSuccessAction());
                    } else {
                        dispatch(logoutFailedAction());
                    }
                });
            }
        }
    };
};

const doLogin = (ident, password, isPersistent) => {
    return dispatch => {
        dispatch(loginStartAction());
        dispatch(requestAuthIdentAction(ident));
        getAuthIdent(dispatch, ident, isPersistent).then(authIdent => {
            if (authIdent && !authIdent.isError) {
                dispatch(receiveAuthIdentAction(authIdent));
                switch (_.toLower(authIdent.type)) {
                    case 'venicloud': {
                        if (password) {
                            loginVeniCloud(authIdent.ident, password, isPersistent)
                                .then(loginResult => {
                                    if (loginResult && !loginResult.isError) {
                                        dispatch(loginSuccessAction());
                                    } else {
                                        dispatch(loginFailedAction());
                                    }
                                })
                                .catch(error => {
                                    console.error(error.message);
                                    dispatch(loginFailedAction());
                                });
                        } else {
                            dispatch(loginCanceledAction());
                        }
                        break;
                    }
                    case 'microsoft': {
                        getMicrosoftConfig(dispatch)
                            .then(config => {
                                loginMicrosoft(config, authIdent.ident).catch(error => {
                                    console.error(error.message);
                                    dispatch(loginFailedAction());
                                });
                            })
                            .catch(error => {
                                console.error(error.message);
                                dispatch(loginFailedAction());
                            });
                        break;
                    }
                    case 'prodreg': {
                        getProdRegConfig(dispatch)
                            .then(config => {
                                loginProdReg(config, authIdent.ident).catch(error => {
                                    console.error(error.message);
                                    dispatch(loginFailedAction());
                                });
                            })
                            .catch(error => {
                                console.error(error.message);
                                dispatch(loginFailedAction());
                            });

                        break;
                    }
                    default: {
                        console.error(`${authIdent.type} is not a valid authenticator!`);
                        dispatch(loginFailedAction());
                        dispatch(logoutStartAction());
                        logout(dispatch).then(result => {
                            if (result && !result.isError) {
                                dispatch(logoutSuccessAction());
                            } else {
                                dispatch(logoutFailedAction());
                            }
                        });
                    }
                }
            } else {
                dispatch(receiveAuthIdentAction(null));
                dispatch(loginFailedAction());
            }
        });
    };
};

const doLogout = () => {
    return dispatch => {
        dispatch(logoutStartAction());
        logout(dispatch).then(result => {
            if (result && !result.isError) {
                dispatch(logoutSuccessAction());
            } else {
                dispatch(logoutFailedAction());
            }
        });
    };
};

export default { fetchAuthIdent, doValidateAuth, doLogin, doLogout };
