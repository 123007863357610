import React from 'react';
//import _ from 'lodash';
import { Message, Icon } from 'semantic-ui-react';
import '../phrase.css';

class PhraseMessageComponent extends React.Component {
    render() {
        const {
            label,
            showEdit,
            text,
            as,
            attached,
            children,
            className,
            color,
            compact,
            content,
            error,
            floating,
            header,
            hidden,
            icon,
            info,
            list,
            negative,
            onDismiss,
            positive,
            size,
            success,
            visible,
            warning,
        } = this.props;
        return (
            <React.Fragment>
                {showEdit && this.renderEditIcon()}
                <Message
                    as={as}
                    attached={attached}
                    children={children}
                    className={className ? `phrase-message ${className}` : 'phrase-message'}
                    color={color}
                    compact={compact}
                    content={content || text}
                    error={error}
                    floating={floating}
                    header={header || label}
                    hidden={hidden}
                    icon={icon}
                    info={info}
                    list={list}
                    negative={negative}
                    onDismiss={onDismiss}
                    positive={positive}
                    size={size}
                    success={success}
                    visible={visible}
                    warning={warning}
                >
                    {children}
                </Message>
            </React.Fragment>
        );
    }

    renderEditIcon = () => {
        return <Icon name="edit" className="phrase-edit-icon right" onClick={this.props.handleOpenEdit} />;
    };
}

export default PhraseMessageComponent;
