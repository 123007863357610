import React, { Suspense } from 'react';
import _ from 'lodash';
import { Responsive, Visibility } from 'semantic-ui-react';
import { Loader } from '../../common';
import { ErrorMessage, SuccessMessage, WarningMessage } from '../../components';
import lazy from '../../utility/lazy';
import { ModuleMenu, ModuleTitlebar } from './components';
import './modulewrapper.css';
import Pullable from 'react-pullable';

var ActiveModule = null;

class ModuleWrapperComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prevRoute: null,
            canPullToRefresh: true,
        };
    }

    componentDidMount() {
        const { hasLoadedAppContext, modules, defaultModuleName, menu } = this.props;
        const { moduleName, subModuleName } = this.props.match.params;
        if (hasLoadedAppContext) {
            const module = this.getModuleByName(modules, moduleName || defaultModuleName);
            this.setModule(module);
            if (module && (!moduleName || !subModuleName)) {
                this.navigateToModule(module, menu);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {
            hasLoadedAppContext,
            modules,
            defaultModuleName,
            fetchModulePhrases,
            isLoadingModulePhrases,
            hasLoadedPortalPhrases,
            selectedLang,
            setNavigationPath,
            selectedModule,
            selectedSubModule,
            menu,
            setShowReturnButton,
        } = this.props;
        const { moduleName, subModuleName } = this.props.match.params;
        const module = this.getModuleByName(modules, moduleName || defaultModuleName);
        // If submodule or module have changed update navigationPath
        if (
            _.get(prevProps, 'selectedModule.name') !== _.get(selectedModule, 'name') ||
            _.get(prevProps, 'selectedSubModule.name') !== _.get(selectedSubModule, 'name')
        ) {
            const pathArray = [];
            if (selectedModule) {
                pathArray[0] = {
                    defaultText: selectedModule.name,
                    guid: selectedModule.titleGuid,
                    link: `/${selectedModule.name}`,
                };
            }
            if (selectedSubModule) {
                pathArray[1] = {
                    defaultText: selectedSubModule.name,
                    guid: selectedSubModule.titleGuid,
                    link: `/${selectedModule.name}/${selectedSubModule.name}`,
                };
            }
            setNavigationPath(pathArray);
        }
        if (isLoadingModulePhrases && isLoadingModulePhrases !== prevProps.isLoadingModulePhrases) {
            this.setModule(module);
        }
        if (isLoadingModulePhrases !== prevProps.isLoadingModulePhrases) {
            this.setTitle(module);
        }
        if (
            hasLoadedAppContext &&
            (!_.isEqual(module, selectedModule) ||
                hasLoadedAppContext !== prevProps.hasLoadedAppContext ||
                moduleName !== prevProps.match.params.moduleName ||
                (subModuleName !== prevProps.match.params.subModuleName &&
                    (_.some(_.get(module, 'children'), m => {
                        return _.toLower(m.name) === _.toLower(subModuleName);
                    }) ||
                        !subModuleName)))
        ) {
            setShowReturnButton(false);
            this.setModule(module);
            if (module && (!moduleName || !subModuleName)) {
                this.navigateToModule(module, menu);
            }
        }
        if (
            module &&
            ((moduleName !== prevProps.match.params.moduleName && _.isEmpty(module.phrases)) ||
                selectedLang !== prevProps.selectedLang)
        ) {
            fetchModulePhrases(module.id, selectedLang);
        }
        if (
            module &&
            hasLoadedPortalPhrases &&
            (moduleName !== prevProps.match.params.moduleName ||
                subModuleName !== prevProps.match.params.subModuleName ||
                hasLoadedAppContext !== prevProps.hasLoadedAppContext ||
                hasLoadedPortalPhrases !== prevProps.hasLoadedPortalPhrases ||
                selectedLang !== prevProps.selectedLang)
        ) {
            this.setTitle(module);
        }
        if (
            moduleName !== prevProps.match.params.moduleName ||
            subModuleName !== prevProps.match.params.subModuleName
        ) {
            this.setState({ prevRoute: prevProps.location });
        }
    }

    setTitle = module => {
        const { config, portalPhrases } = this.props;
        const { subModuleName } = this.props.match.params;

        const portalTitle = _.find(portalPhrases, p => {
            return _.toLower(p.guid) === _.toLower(config.titleGuid);
        });

        const moduleTitle = _.find(portalPhrases, p => {
            return _.toLower(p.guid) === _.toLower(module.titleGuid);
        });
        if (subModuleName) {
            const subModule = _.find(module.children, c => {
                return _.toLower(c.name) === _.toLower(subModuleName);
            });
            if (subModule) {
                const subModuleTitle = _.find(portalPhrases, p => {
                    return _.toLower(p.guid) === _.toLower(subModule.titleGuid);
                });
                if (subModuleTitle) {
                    document.title = `${_.get(moduleTitle, 'value') || module.name} - ${
                        _.get(subModuleTitle, 'value') || _.get(subModule, 'name')
                    } - ${_.get(portalTitle, 'value')}`;
                } else {
                    document.title = `${_.get(moduleTitle, 'value') || module.name} - ${_.get(portalTitle, 'value')}`;
                }
            }
        } else {
            document.title = `${_.get(moduleTitle, 'value') || module.name} - ${_.get(portalTitle, 'value')}`;
        }
    };

    setModule = module => {
        const { setSelectedModule, setSelectedSubModule, fetchModulePhrases, selectedLang, isLoadingModulePhrases } =
            this.props;
        const { subModuleName } = this.props.match.params;
        const subModule = _.find(_.get(module, 'children'), m => {
            return _.toLower(m.name) === _.toLower(subModuleName);
        });

        if (module) {
            ActiveModule = lazy(
                () => import(`../../modules/${_.get(module, 'name')}/index.js`),
                _.get(subModule, 'name') || _.get(module, 'name')
            );
        }

        setSelectedModule(module || null);
        setSelectedSubModule(subModule || null);
        if (module && !isLoadingModulePhrases && _.isEmpty(module.phrases)) {
            fetchModulePhrases(module.id, selectedLang);
        }
        this.setTitle(module);
    };

    navigateToModule = (module, menu) => {
        const { moduleName, subModuleName } = this.props.match.params;
        if (!_.isEmpty(module.menu)) {
            this.props.history.push(`/${module.name}/${subModuleName || this.getDefaultSubModule(module, menu)}`);
        } else if (_.get(module, 'config.defaultSubModule')) {
            this.props.history.replace(`/${module.name}/${module.config.defaultSubModule}`);
        } else if (!moduleName) {
            this.props.history.replace(`/${module.name}`);
        }
    };

    getDefaultSubModule = (module, menu) => {
        return (
            _.get(module, 'config.defaultSubModule') ||
            _.get(module, 'menu[0].value') ||
            _.get(
                _.find(menu, item => item.name === module.name),
                'name'
            )
        );
    };

    getModuleByName = (modules, moduleName) => {
        return _.find(modules, m => {
            return _.toLower(m.name) === _.toLower(moduleName);
        });
    };

    getMenuItem = (item, id) => {
        if (_.isEmpty(item.children)) {
            return _.toLower(item.value) === _.toLower(id);
        }
        return _.some(item.children, function (child) {
            return _.toLower(child.value) === _.toLower(id);
        });
    };

    getSubModuleNameFromParams = () => {
        const { subModuleName } = this.props.match.params;
        if (subModuleName) {
            const subModuleItem = _.find(module.menu, function (o) {
                return o.value.toLowerCase() === subModuleName.toLowerCase();
            });
            return _.get(subModuleItem, 'value');
        }
        return null;
    };

    handleOnScroll = (e, { calculations }) => {
        this.setState({ canPullToRefresh: calculations.topVisible });
    };

    refreshPage = () => {
        const { version } = this.props;
        localStorage.setItem('version', version);
        window.location.reload(true);
    };

    render() {
        const { moduleName } = this.props.match.params;
        const {
            selectedModule,
            hasLoadedAppContext,
            hasLoadedAppContextSimplified,
            isLoadingModule,
            isLoadingModulePhrases,
        } = this.props;

        if (
            !moduleName ||
            !hasLoadedAppContext ||
            !hasLoadedAppContextSimplified ||
            isLoadingModule ||
            isLoadingModulePhrases
        ) {
            return this.renderLoader();
        } else {
            if (!selectedModule) {
                return <div />;
            }
            return (
                <React.Fragment>
                    <Responsive minWidth={Responsive.onlyTablet.maxWidth}
                                className={'module-container module-' + selectedModule.name.toLowerCase()}
                                fireOnMount
                    >
                        {this.renderDesktop()}
                    </Responsive>
                    <Responsive
                        maxWidth={Responsive.onlyTablet.maxWidth}
                        className={'module-container mobile module-' + selectedModule.name.toLowerCase()}
                        fireOnMount
                    >
                        {this.renderMobile()}
                    </Responsive>
                </React.Fragment>
            );
        }
    }

    modifyContainerWidth = () => {
        const currentPath = window.location.pathname;

        if (currentPath === '/Export/SlaughterDataExport' || currentPath === '/Puljeplan') {
            return '2560px';
        } else {
            return '80em';
        }
    };

    renderDesktop = () => {
        const { prevRoute } = this.state;
        const { moduleName, subModuleName } = this.props.match.params;
        const { selectedModule, selectedSubModule, showReturnButton, authIdent } = this.props;

        if (_.isEmpty(selectedModule.menu)) {
            return (
                <div className="module-main-container">
                    <div className="module-content-container" style={{ maxWidth: this.modifyContainerWidth() }}>
                        {this.renderModule()}
                    </div>
                </div>
            );
        } else {
            if (!selectedSubModule) {
                return <div />;
            }
            return (
                <React.Fragment>
                    <ModuleTitlebar
                        module={selectedModule}
                        subModule={selectedSubModule}
                        showReturnButton={showReturnButton}
                        prevRoute={prevRoute}
                    />
                    <div className="module-main-container">
                        <div className="module-content-container">
                            {this.renderMessages()}
                            {this.renderModule()}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    renderMobile = () => {
        const { subModuleName } = this.props.match.params;
        const { prevRoute, canPullToRefresh } = this.state;
        const {
            selectedModule,
            selectedSubModule,
            handleShowSidebar,
            handleHideSidebar,
            sidebarIsVisible,
            showReturnButton,
            notifications,
            updateReadNotifications,
            isLoadingNotifications,
            isNotificationsVisible,
            showNotifications,
            authIdent,
            perms,
        } = this.props;
        /*const selectedModuleMenuChildren = _.get(
            _.find(menu, ['value', _.get(selectedModule, 'name')]),
            'children'
        );*/
        return (
            <Visibility continuous onUpdate={this.handleOnScroll} className="visibility-container">
                <Pullable onRefresh={this.refreshPage} resistance={5} disabled={!canPullToRefresh}>
                    <ModuleTitlebar
                        module={selectedModule}
                        subModule={selectedSubModule}
                        subModuleName={subModuleName}
                        showReturnButton={showReturnButton}
                        toggleSidebar={sidebarIsVisible ? handleHideSidebar : handleShowSidebar}
                        prevRoute={prevRoute}
                        {...{
                            notifications,
                            isLoadingNotifications,
                            updateReadNotifications,
                            isNotificationsVisible,
                            showNotifications,
                        }}
                        ident={authIdent.name}
                        perms={perms}
                    />
                    {this.renderMessages()}
                    <div className="module-main-container">
                        <div className="module-content-container">{this.renderModule()}</div>
                    </div>
                </Pullable>
            </Visibility>
        );
    };

    renderModule = () => {
        if (ActiveModule) {
            return (
                <Suspense fallback={this.renderLoader()}>
                    <ActiveModule />
                </Suspense>
            );
        }
    };

    renderLoader = () => {
        return <Loader />;
    };

    renderMessages = () => {
        const { messages } = this.props;
        return (
            <div className="error-message-wrapper">
                {_.map(messages, (message, key) => {
                    return this.renderMessage(message, key);
                })}
            </div>
        );
    };

    renderMessage = (message, key) => {
        const { removeMessage } = this.props;
        if (message.type === 'error') {
            return (
                <ErrorMessage
                    key={key}
                    guid={message.guid}
                    header={message.title}
                    message={message.message}
                    onDismiss={guid => removeMessage(guid)}
                />
            );
        } else if (message.type === 'success') {
            return (
                <SuccessMessage
                    key={key}
                    guid={message.guid}
                    header={message.title}
                    message={message.message}
                    onDismiss={guid => removeMessage(guid)}
                />
            );
        } else {
            return (
                <WarningMessage
                    key={key}
                    guid={message.guid}
                    header={message.title}
                    message={message.message}
                    onDismiss={guid => removeMessage(guid)}
                />
            );
        }
    };
}

export default ModuleWrapperComponent;
