// @app/components/Puljeplan/duck/actions.js
import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    requestPuljeplan: [],
    receivePuljeplan: ['puljeplan'],
    requestPuljeplanConfiguration: [],
    receivePuljeplanConfiguration: ['puljeplanConfiguration'],
    requestPuljeplanDefinitions: [],
    receivePuljeplanDefinitions: ['puljeplanDefinitions'],
    savePuljeplanConfiguration: [],
    doneSavingPuljeplanConfiguration: []
});

export { Creators, Types };
