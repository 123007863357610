// @app/components/MyPage/duck/actions.js
import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    requestMyProfile: [],
    receiveMyProfile: ['profile'],
    requestSaveUserAvatarImage: [],
    receiveSaveUserAvatarImage: ['result'],
    myPageUpdateProfileContactInfo: ['contactInfo'],
    requestLoginLog: [],
    receiveLoginLog: ['loginLog'],
    requestMoreLoginLog: [],
    receiveMoreLoginLog: ['loginLog'],
});

export { Creators, Types };
