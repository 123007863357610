import { Creators } from './actions';
import { getAllNotifications, markNotificationAsRead, markNotificationAsDismissed } from './api';

const showNotificationsAction = Creators.notificationsShowNotifications;
const hideNotificationsAction = Creators.notificationsHideNotifications;
const requestNotificationsAction = Creators.notificationsRequestNotifications;
const receiveNotificationsAction = Creators.notificationsReceiveNotifications;
const markAsReadAction = Creators.notificationsMarkNotificationAsRead;
const markAsDismissedAction = Creators.notificationsMarkNotificationAsDismissed;
const receiveNotificationAction = Creators.notificationsReceiveNotification;

const showNotifications = () => {
    return dispatch => {
        dispatch(showNotificationsAction());
    };
};

const hideNotifications = () => {
    return dispatch => {
        dispatch(hideNotificationsAction());
    };
};

const fetchAllNotifications = (langId, skip, take) => {
    return dispatch => {
        dispatch(requestNotificationsAction());
        getAllNotifications(dispatch, langId, skip, take).then(result => {
            if (result && !result.isError) {
                dispatch(receiveNotificationsAction(result.items));
            } else {
                dispatch(receiveNotificationsAction([]));
            }
        });
    };
};

const markAsRead = id => {
    return dispatch => {
        markNotificationAsRead(dispatch, id).then(result => {
            if (result && !result.isError) {
                dispatch(markAsReadAction(id));
            }
        });
    };
};

const markAsDismissed = id => {
    return dispatch => {
        markNotificationAsDismissed(dispatch, id).then(result => {
            if (result && !result.isError) {
                dispatch(markAsDismissedAction(id));
            }
        });
    };
};

const receiveNotification = notification => {
    return dispatch => {
        dispatch(receiveNotificationAction(notification));
    };
};

export default {
    showNotifications,
    hideNotifications,
    fetchAllNotifications,
    markAsRead,
    markAsDismissed,
    receiveNotification,
};
