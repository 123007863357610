import React from 'react';
import styled from 'styled-components';
import AnimalTypeButton from '../Buttons/AnimalTypeButton';

const ContainerAnimalType = styled.div`
    width: 17rem;
    display: flex;
    flex-direction: row;
    padding-top: 0;
    align-items: center;
`;

const WrapperAnimalType = styled.div`
    display: flex;
    align-items: stretch;
    margin: 0 0.5rem;
`;

const filterItems = [
    {
        name: 'Storfe',
        url: 'https://mittfatlandstorage.blob.core.windows.net/public/faa48e3a78d44f748080ea867e8cea5a.PNG',
    },
    {
        name: 'Gris',
        url: 'https://mittfatlandstorage.blob.core.windows.net/public/b7035584e52543d18ef80d0434046759.PNG',
    },
    {
        name: 'Småfe',
        url: 'https://mittfatlandstorage.blob.core.windows.net/public/bbcc408ab5ea41258d7bdbc94878766d.PNG',
    },
];

const PrimaryFilter = ({ setSelected, selected, onClickAnimalType }) => {
    const getFilterItem = item => setSelected(item);

    return (
        <ContainerAnimalType>
            <WrapperAnimalType>
                {filterItems.map(({ name, url }) => (
                    <AnimalTypeButton
                        key={name}
                        name={name}
                        url={url}
                        isSelected={selected === name}
                        onGetFilterItem={getFilterItem}
                        onClickAnimalType={onClickAnimalType}
                    />
                ))}
            </WrapperAnimalType>
        </ContainerAnimalType>
    );
};

export default PrimaryFilter;
