import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Responsive, Image, Dimmer, Loader, Divider } from 'semantic-ui-react';
import { SidebarMenu } from './components';
import { DeviceTypes } from '../../utility/deviceTypes';
import './sidebar.css';
import { PortalPhrase, PhraseButton } from '../../components';

class SidebarComponent extends React.Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = e => {
        const { deviceType, isSidebarVisible } = this.props;
        if (
            isSidebarVisible &&
            (deviceType === DeviceTypes.mobile || deviceType === DeviceTypes.tablet) &&
            this.wrapperRef &&
            !this.wrapperRef.contains(e.target)
        ) {
            this.props.handleHideSidebar();
        }
    };

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleToggleSidebar = () => {
        if (this.props.isSidebarVisible) {
            this.props.handleHideSidebar();
        } else {
            this.props.handleShowSidebar();
        }
    };

    handleMenuItemClick = newModuleName => {
        const { modules, deviceType } = this.props;
        const { moduleName, subModuleName, foo } = this.props.match.params;
        const module = _.find(modules, m => {
            return _.toLower(m.name) === _.toLower(newModuleName);
        });
        const defaultSubModuleName = _.get(module, 'config.defaultSubModule') || _.get(module, 'menu[0].value');
        if (!_.isEmpty(_.get(module, 'menu'))) {
            if (
                _.toLower(newModuleName) !== _.toLower(moduleName) ||
                (subModuleName && !_.some(module.menu, m => _.toLower(m.name) === _.toLower(subModuleName)))
            ) {
                this.props.history.push(`/${newModuleName}/${defaultSubModuleName}`);
            }
        } else if (
            _.toLower(newModuleName) !== _.toLower(moduleName) ||
            (subModuleName && subModuleName !== defaultSubModuleName)
        ) {
            this.props.history.push(`/${newModuleName}`);
        } else if (foo) {
            if (defaultSubModuleName) {
                this.props.history.push(`/${newModuleName}/${defaultSubModuleName}`);
            } else {
                this.props.history.push(`/${newModuleName}`);
            }
        }
        if (deviceType === DeviceTypes.mobile || deviceType === DeviceTypes.tablet) {
            this.props.handleHideSidebar();
        }
    };

    handleLogoClick = () => {
        const { defaultModuleName } = this.props;
        this.props.history.push(`/${defaultModuleName}`);
    };

    handleSignOutClick = () => {
        this.props.history.push(`/SignOut`);
    };

    render() {
        const { isSidebarVisible } = this.props;
        return (
            <div ref={this.setWrapperRef}>
                <Responsive
                    minWidth={Responsive.onlyTablet.maxWidth}
                    className={isSidebarVisible ? 'sidebar-container' : 'sidebar-container collapse'}
                    fireOnMount
                    aria-hidden={!isSidebarVisible}
                >
                    {this.renderSidebarDesktop()}
                </Responsive>
                <Responsive
                    maxWidth={Responsive.onlyTablet.maxWidth}
                    className={isSidebarVisible ? 'sidebar-container mobile' : 'sidebar-container mobile hide'}
                    fireOnMount
                    aria-hidden={!isSidebarVisible}
                >
                    {this.renderSidebarMobile()}
                </Responsive>
            </div>
        );
    }

    renderSidebarDesktop = () => {
        const { moduleName, subModuleName } = this.props.match.params;
        const { menu, isSidebarVisible, modules, perms, history } = this.props;
        return (
            <React.Fragment>
                {this.renderSidebarHeader()}
                <SidebarMenu
                    modules={modules}
                    menu={menu}
                    moduleName={moduleName}
                    subModuleName={subModuleName}
                    isSidebarVisible={isSidebarVisible}
                    handleItemClick={item => this.handleMenuItemClick(item.value)}
                    perms={perms}
                    history={history}
                />
            </React.Fragment>
        );
    };

    renderSidebarMobile = () => {
        const { moduleName, subModuleName } = this.props.match.params;
        const { menu, isSidebarVisible, modules, perms, history } = this.props;
        return (
            <React.Fragment>
                {this.renderSidebarHeader()}
                <SidebarMenu
                    modules={_.map(modules, modul => {
                        return { name: modul.name, menu: modul.menu };
                    })}
                    menu={menu}
                    moduleName={moduleName}
                    subModuleName={subModuleName}
                    isSidebarVisible={isSidebarVisible}
                    handleItemClick={item => this.handleMenuItemClick(item.value)}
                    perms={perms}
                    history={history}
                />
                <div className="sidebar-footer">
                    <PortalPhrase
                        defaultText="Logg ut"
                        guid="ac3d777c-3add-43a6-a23e-6e880dd30866"
                        hasText
                        divStyle={{ display: 'flex', marginTop: '0.4em' }}
                    >
                        <PhraseButton
                            className="sidebar-footer-mobile-logout"
                            icon="log out"
                            onClick={this.handleSignOutClick}
                        />
                    </PortalPhrase>
                </div>
            </React.Fragment>
        );
    };

    renderSidebarHeader = () => {
        const { config } = this.props;
        return (
            <div className="sidebar-header-container" onClick={this.handleLogoClick}>
                <Image
                    src={_.get(config, 'logo')}
                    className="sidebar-logo"
                    verticalAlign="middle"
                    alt={_.get(config, 'name') + ' logo'}
                />
            </div>
        );
    };

    renderSidebarFooter = () => {
        return (
            <div className="sidebar-footer">
                <div className="left">Powered by VeniCloud</div>
                <div className="right" />
            </div>
        );
    };
}

export default SidebarComponent;
