import { connect } from 'react-redux';
import GetFileComponent from './GetFileComponent';
import { storageOperations } from './duck';

const mapStateToProps = props => {
    return {
        ...props,
    };
};

const mapDispatchToProps = dispatch => {
    const doGetFile = (moduleIdent, fileId, isDownload) => {
        return dispatch(storageOperations.doGetFile(moduleIdent, fileId, isDownload));
    };
    return { doGetFile };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetFileComponent);
