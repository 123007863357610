import { connect } from 'react-redux';
import SidebarComponent from './SidebarComponent';
import { sidebarOperations } from './duck';
import { moduleOperations } from '../../common/ModuleWrapper/duck';
import { withRouter } from 'react-router';

const mapStateToProps = (state, props) => {
    const { config, menu, modules, deviceType, defaultModuleName, perms } = state.app;
    const { module, selectedModuleName, selectedSubModuleName } = state.module;
    const { isSidebarVisible } = state.sidebar;
    return {
        config,
        menu,
        modules,
        module,
        selectedModuleName,
        selectedSubModuleName,
        isSidebarVisible,
        deviceType,
        defaultModuleName,
        perms,
        ...props,
    };
};

const mapDispatchToProps = dispatch => {
    const fetchModule = (moduleName, preferredLang) => {
        dispatch(moduleOperations.fetchModule(moduleName, preferredLang));
    };
    const handleShowSidebar = () => {
        dispatch(sidebarOperations.handleShowSidebar());
    };
    const handleHideSidebar = () => {
        dispatch(sidebarOperations.handleHideSidebar());
    };
    return { fetchModule, handleShowSidebar, handleHideSidebar };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarComponent));
