// duck/reducers.js
import { createReducer } from 'reduxsauce';
import _ from 'lodash';
import { Types } from './actions';
import { default as uuid } from 'uuid/v4';

export const INITIAL_STATE = {
    authIdent: null,
    isLoadingAuthIdent: false,
    isError: false,
    messages: [],
    errorRedirectUri: null,
    portal: null,
    user: null,
    version: null,
    isLoadingVersion: false,
    config: null,
    isLoadingConfig: false,
    menu: null,
    modules: [],
    selectedModule: null,
    selectedSubModule: null,
    globalPhrases: null,
    isLoadingGlobalPhrases: false,
    hasLoadedGlobalPhrases: false,
    isSavingGlobalPhrases: false,
    portalPhrases: null,
    isLoadingPortalPhrases: false,
    hasLoadedPortalPhrases: false,
    isSavingPortalPhrases: false,
    isLoadingModulePhrases: false,
    hasLoadedModulePhrases: false,
    isSavingModulePhrases: false,
    isLoading: true,
    hasLoadedAppContext: false,
    hasLoadedAppContextSimplified: false,
    defaultModuleName: null,
    deviceType: null,
    selectedLang: null,
    fallbackLang: 'nb',
    isPhraseEditMode: false,
    navigationPath: [
        {
            defaultText: 'Front page',
            guid: 'caa7f38e-bffa-470d-ae12-187ef70c049a',
            link: '/',
        },
    ],
    showReturnButton: false,
    perms: null,
    isLoadingProducerInfo: false,
    producerInfo: null,
};

export const requestAuthIdent = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        token: null,
        isAuthenticated: false,
        isLoadingAuthIdent: true,
    };
};

export const receiveAuthIdent = (state = INITIAL_STATE, action) => {
    const { authIdent } = action;
    return {
        ...state,
        authIdent: authIdent,
        isLoadingAuthIdent: false,
    };
};

export const requestVersion = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingVersion: true,
    };
};

export const receiveVersion = (state = INITIAL_STATE, action) => {
    const { version } = action;
    return {
        ...state,
        version: version,
        isLoadingVersion: false,
    };
};

export const requestPortalConfig = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingConfig: true,
    };
};

export const receivePortalConfig = (state = INITIAL_STATE, action) => {
    const { config } = action;
    return {
        ...state,
        config: config,
        isLoadingConfig: false,
        selectedLang: state.selectedLang ? state.selectedLang : config.preferredLang,
    };
};

export const requestAppContext = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoading: true,
        hasLoadedAppContext: false,
    };
};

export const receiveAppContext = (state = INITIAL_STATE, action) => {
    const { appContext } = action;
    return {
        ...state,
        selectedLang: _.get(appContext, 'config.preferredLang') || 'nb',
        user: _.get(appContext, 'user'),
        config: _.get(appContext, 'config'),
        menu: _.get(appContext, 'menu'),
        modules: _.get(appContext, 'modules'),
        hasLoadedAppContext: true,
        defaultModuleName: _.get(appContext, 'config.defaultModule') || _.get(appContext, 'menu[0].value') || null,
        isLoading: false,
    };
};

export const requestAppContextSimplified = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoading: true,
        hasLoadedAppContextSimplified: false,
    };
};

export const receiveAppContextSimplified = (state = INITIAL_STATE, action) => {
    const { appContextSimplified } = action;
    return {
        ...state,
        hasLoadedAppContextSimplified: true,
        perms: _.get(appContextSimplified, 'perms'),
        isLoading: false,
    };
};

export const setSelectedModule = (state = INITIAL_STATE, action) => {
    const { module } = action;
    return {
        ...state,
        selectedModule: module,
    };
};

export const setSelectedSubModule = (state = INITIAL_STATE, action) => {
    const { subModule } = action;
    return {
        ...state,
        selectedSubModule: subModule,
    };
};

export const requestGlobalPhrases = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingGlobalPhrases: true,
        hasLoadedGlobalPhrases: false,
    };
};

export const receiveGlobalPhrases = (state = INITIAL_STATE, action) => {
    const { phrases } = action;
    return {
        ...state,
        globalPhrases: phrases,
        isLoadingGlobalPhrases: false,
        hasLoadedGlobalPhrases: true,
    };
};

export const requestPortalPhrases = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingPortalPhrases: true,
        hasLoadedPortalPhrases: false,
    };
};

export const receivePortalPhrases = (state = INITIAL_STATE, action) => {
    const { phrases } = action;
    return {
        ...state,
        portalPhrases: phrases,
        isLoadingPortalPhrases: false,
        hasLoadedPortalPhrases: true,
    };
};
export const requestModulePhrases = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingModulePhrases: true,
        hasLoadedModulePhrases: false,
    };
};

export const receiveModulePhrases = (state = INITIAL_STATE, action) => {
    const { moduleIdent, phrases } = action;
    return {
        ...state,
        modules: state.modules.map(module => (module.id === moduleIdent ? { ...module, phrases: phrases } : module)),
        isLoadingModulePhrases: false,
        hasLoadedModulePhrases: true,
    };
};

export const setDeviceType = (state = INITIAL_STATE, action) => {
    const { deviceType } = action;
    return {
        ...state,
        deviceType: deviceType,
    };
};

export const requestSaveGlobalPhrase = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isSavingGlobalPhrases: true,
    };
};
export const receiveSaveGlobalPhrase = (state = INITIAL_STATE, action) => {
    const { lang, phrases } = action;
    return {
        ...state,
        isSavingGlobalPhrases: false,
    };
};

export const requestSavePortalPhrase = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isSavingPortalPhrases: true,
    };
};

export const receiveSavePortalPhrase = (state = INITIAL_STATE, action) => {
    const { lang, phrases } = action;
    return {
        ...state,
        isSavingPortalPhrases: false,
    };
};

export const requestSaveModulePhrase = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isSavingModulePhrases: true,
    };
};

export const receiveSaveModulePhrase = (state = INITIAL_STATE, action) => {
    const { lang, phrases } = action;
    return {
        ...state,
        isSavingModulePhrases: false,
    };
};

export const setSelectedLang = (state = INITIAL_STATE, action) => {
    const { lang } = action;
    return {
        ...state,
        selectedLang: lang,
    };
};

export const setPhraseEditMode = (state = INITIAL_STATE, action) => {
    const { isEdit } = action;
    return {
        ...state,
        isPhraseEditMode: isEdit,
    };
};

export const handleMessage = (state = INITIAL_STATE, action) => {
    console.log(action)
    const { messageText, messageType, messageTitle } = action;
    const message = {
        guid: uuid(),
        message: messageText,
        type: messageType,
        title: messageTitle,
    };
    return {
        ...state,
        messages: [...state.messages, message],
    };
};

export const removeMessage = (state = INITIAL_STATE, action) => {
    const { guid } = action;
    return {
        ...state,
        messages: _.filter(state.messages, e => {
            return e.guid !== guid;
        }),
    };
};

export const addNavigationPathElement = (state = INITIAL_STATE, action) => {
    const { pathElement } = action;
    const newPath = _.uniq([..._.get(state, 'navigationPath', []), pathElement]);

    return { ...state, navigationPath: newPath };
};

export const setNavigationPath = (state = INITIAL_STATE, action) => {
    const { path } = action;
    return {
        ...state,
        navigationPath: [...INITIAL_STATE.navigationPath, ...path],
    };
};

export const setShowReturnButton = (state = INITIAL_STATE, action) => {
    const { show } = action;
    return { ...state, showReturnButton: show };
};

export const requestProducerInfo = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingProducerInfo: true }
}

export const receiveProducerInfo = (state = INITIAL_STATE, action) => {
    const { producerInfo } = action;
    return { ...state, producerInfo: producerInfo, isLoadingProducerInfo: false }
}

export const HANDLERS = {
    [Types.APP_HANDLE_MESSAGE]: handleMessage,
    [Types.APP_REMOVE_MESSAGE]: removeMessage,
    [Types.APP_REQUEST_AUTH_IDENT]: requestAuthIdent,
    [Types.APP_RECEIVE_AUTH_IDENT]: receiveAuthIdent,
    [Types.APP_REQUEST_VERSION]: requestVersion,
    [Types.APP_RECEIVE_VERSION]: receiveVersion,
    [Types.APP_REQUEST_PORTAL_CONFIG]: requestPortalConfig,
    [Types.APP_RECEIVE_PORTAL_CONFIG]: receivePortalConfig,
    [Types.APP_REQUEST_APP_CONTEXT]: requestAppContext,
    [Types.APP_RECEIVE_APP_CONTEXT]: receiveAppContext,
    [Types.APP_REQUEST_APP_CONTEXT_SIMPLIFIED]: requestAppContextSimplified,
    [Types.APP_RECEIVE_APP_CONTEXT_SIMPLIFIED]: receiveAppContextSimplified,
    [Types.APP_SET_SELECTED_MODULE]: setSelectedModule,
    [Types.APP_SET_SELECTED_SUB_MODULE]: setSelectedSubModule,
    [Types.APP_REQUEST_GLOBAL_PHRASES]: requestGlobalPhrases,
    [Types.APP_RECEIVE_GLOBAL_PHRASES]: receiveGlobalPhrases,
    [Types.APP_REQUEST_PORTAL_PHRASES]: requestPortalPhrases,
    [Types.APP_RECEIVE_PORTAL_PHRASES]: receivePortalPhrases,
    [Types.APP_REQUEST_MODULE_PHRASES]: requestModulePhrases,
    [Types.APP_RECEIVE_MODULE_PHRASES]: receiveModulePhrases,
    [Types.APP_SET_DEVICE_TYPE]: setDeviceType,
    [Types.APP_REQUEST_SAVE_GLOBAL_PHRASE]: requestSaveGlobalPhrase,
    [Types.APP_RECEIVE_SAVE_GLOBAL_PHRASE]: receiveSaveGlobalPhrase,
    [Types.APP_REQUEST_SAVE_PORTAL_PHRASE]: requestSavePortalPhrase,
    [Types.APP_RECEIVE_SAVE_PORTAL_PHRASE]: receiveSavePortalPhrase,
    [Types.APP_REQUEST_SAVE_MODULE_PHRASE]: requestSaveModulePhrase,
    [Types.APP_RECEIVE_SAVE_MODULE_PHRASE]: receiveSaveModulePhrase,
    [Types.APP_SET_SELECTED_LANG]: setSelectedLang,
    [Types.APP_SET_PHRASE_EDIT_MODE]: setPhraseEditMode,
    [Types.APP_ADD_NAVIGATION_PATH_ELEMENT]: addNavigationPathElement,
    [Types.APP_SET_NAVIGATION_PATH]: setNavigationPath,
    [Types.APP_SET_SHOW_RETURN_BUTTON]: setShowReturnButton,
    [Types.APP_REQUEST_PRODUCER_INFO]: requestProducerInfo,
    [Types.APP_RECEIVE_PRODUCER_INFO]: receiveProducerInfo,
};

export default createReducer(INITIAL_STATE, HANDLERS);
