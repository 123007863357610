import { apiFetch } from '../../../utility';

export function getAllNotifications(dispatch, langId, skip, take) {
    const url = `api/Notification/GetAllNotifications?langId=${langId}&skip=${skip}&take=${take}`;
    return apiFetch(dispatch, url);
}

export function markNotificationAsRead(dispatch, id) {
    const url = `api/Notification/MarkNotificationAsRead`;
    const options = {
        method: 'patch',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(id),
    };
    return apiFetch(dispatch, url, options);
}

export function markNotificationAsDismissed(dispatch, id) {
    const url = `api/Notification/MarkNotificationAsDismissed`;
    const options = {
        method: 'patch',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(id),
    };
    return apiFetch(dispatch, url, options);
}
