import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import _ from 'lodash';

export const INITIAL_STATE = {
    registeredRegistrations: [],
    isLoadingRegistrations: false,
    isLoadingMoreRegistrations: false,
    producerInfo: null,
    isLoadingProducerInfo: false,
    preparedRegistrations: [],
    isSendingRegistrations: false,
    savedRegistrations: [],
    isLoadingLivestock: false,
    isLoadingMoreLivestock: false,
    livestock: [],
    isLoadingSlaughterData: false,
    isLoadingMoreSlaughterData: false,
    slaughterData: [],
    isLoadingSlaughterDataReport: false,
    isLoadingMoreSlaughterDataReport: false,
    slaughterDataReport: {
        items: [],
    },
};

export const requestRegistrations = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingRegistrations: true,
    };
};

export const receiveRegistrations = (state = INITIAL_STATE, action) => {
    const { registrations } = action;
    return {
        ...state,
        requestedRegistrations: registrations,
        isLoadingRegistrations: false,
    };
};

export const requestMoreRegistrations = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingMoreRegistrations: true,
    };
};

export const receiveMoreRegistrations = (state = INITIAL_STATE, action) => {
    const { moreRegistrations } = action;
    const { requestedRegistrations } = state;
    return {
        ...state,
        requestedRegistrations: [...requestedRegistrations, ...moreRegistrations],
        isLoadingMoreRegistrations: false,
    };
};

export const addRegistration = (state = INITIAL_STATE, action) => {
    const { registration } = action;
    const { preparedRegistrations } = state;
    return {
        ...state,
        preparedRegistrations: [...preparedRegistrations, registration],
        savedRegistrations: [],
    };
};

export const removeRegistration = (state = INITIAL_STATE, action) => {
    const { index } = action;
    const { preparedRegistrations } = state;
    return {
        ...state,
        preparedRegistrations: _.flatten([
            _.take(preparedRegistrations, index),
            _.drop(preparedRegistrations, index + 1),
        ]),
    };
};

export const requestProducerInfo = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingProducerInfo: true,
    };
};

export const receiveProducerInfo = (state = INITIAL_STATE, action) => {
    const { producerInfo } = action;
    return {
        ...state,
        producerInfo: producerInfo,
        isLoadingProducerInfo: false,
    };
};

export const requestSaveRegistrations = (state = INITIAL_STATE) => {
    return {
        ...state,
        isSavingRegistrations: true,
    };
};

export const receiveSaveRegistrations = (state = INITIAL_STATE, action) => {
    const { savedRegistrations } = action;
    const { preparedRegistrations } = state;
    return {
        ...state,
        savedRegistrations: savedRegistrations,
        preparedRegistrations: _.filter(preparedRegistrations, (item, i) => savedRegistrations[i].isError),
        isSavingRegistrations: false,
    };
};

export const requestLivestock = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingLivestock: true,
    };
};

export const receiveLivestock = (state = INITIAL_STATE, action) => {
    const { livestock } = action;
    return {
        ...state,
        isLoadingLivestock: false,
        livestock: livestock.items,
    };
};

export const requestMoreLivestock = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingMoreLivestock: true,
    };
};

export const receiveMoreLivestock = (state = INITIAL_STATE, action) => {
    const { moreLivestock } = action;
    const { livestock } = state;
    return {
        ...state,
        isLoadingMoreLivestock: false,
        livestock: [...livestock, ...moreLivestock.items],
    };
};

export const requestSlaughterData = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingSlaughterData: true,
    };
};

export const receiveSlaughterData = (state = INITIAL_STATE, action) => {
    const { slaughterData } = action;
    const mapped = slaughterData.items.map(s => s.item);
    return {
        ...state,
        isLoadingSlaughterData: false,
        slaughterData: slaughterData.items,
        slaughterDataItems: mapped,
    };
};

export const requestMoreSlaughterData = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingMoreSlaughterData: true,
    };
};

export const receiveMoreSlaughterData = (state = INITIAL_STATE, action) => {
    const { moreSlaughterData } = action;
    const { slaughterData, slaughterDataItems } = state;

    return {
        ...state,
        isLoadingMoreSlaughterData: false,
        slaughterData: [...slaughterData, ...moreSlaughterData.items],
        slaughterDataItems: [
            ...slaughterDataItems,
            ...moreSlaughterData.items.map(i => i.item)
        ]
    };
};

export const requestSlaughterDataReport = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingSlaughterDataReport: true,
    };
};

export const receiveSlaughterDataReport = (state = INITIAL_STATE, action) => {
    const { slaughterDataReport } = action;
    return {
        ...state,
        isLoadingSlaughterDataReport: false,
        slaughterDataReport: {
            ...slaughterDataReport,
            items: slaughterDataReport.items.map(r => {
                return {
                    ...r.item,
                    highlights: true,
                };
            }),
        },
    };
};

export const requestMoreSlaughterDataReport = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingMoreSlaughterDataReport: true,
    };
};

export const receiveMoreSlaughterDataReport = (state = INITIAL_STATE, action) => {
    const { moreSlaughterDataReport } = action;
    const { slaughterDataReport } = state;
    return {
        ...state,
        isLoadingMoreSlaughterDataReport: false,
        slaughterDataReport: [...slaughterDataReport, ...moreSlaughterDataReport.items],
    };
};

export const HANDLERS = {
    [Types.REQUEST_REGISTRATIONS]: requestRegistrations,
    [Types.RECEIVE_REGISTRATIONS]: receiveRegistrations,
    [Types.REQUEST_MORE_REGISTRATIONS]: requestMoreRegistrations,
    [Types.RECEIVE_MORE_REGISTRATIONS]: receiveMoreRegistrations,
    [Types.ADD_REGISTRATION]: addRegistration,
    [Types.REMOVE_REGISTRATION]: removeRegistration,
    [Types.REQUEST_PRODUCER_INFO]: requestProducerInfo,
    [Types.RECEIVE_PRODUCER_INFO]: receiveProducerInfo,
    [Types.REQUEST_SAVE_REGISTRATIONS]: requestSaveRegistrations,
    [Types.RECEIVE_SAVE_REGISTRATIONS]: receiveSaveRegistrations,
    [Types.REQUEST_LIVESTOCK]: requestLivestock,
    [Types.RECEIVE_LIVESTOCK]: receiveLivestock,
    [Types.REQUEST_MORE_LIVESTOCK]: requestMoreLivestock,
    [Types.RECEIVE_MORE_LIVESTOCK]: receiveMoreLivestock,
    [Types.REQUEST_SLAUGHTER_DATA]: requestSlaughterData,
    [Types.RECEIVE_SLAUGHTER_DATA]: receiveSlaughterData,
    [Types.REQUEST_MORE_SLAUGHTER_DATA]: requestMoreSlaughterData,
    [Types.RECEIVE_MORE_SLAUGHTER_DATA]: receiveMoreSlaughterData,
    [Types.REQUEST_SLAUGHTER_DATA_REPORT]: requestSlaughterDataReport,
    [Types.RECEIVE_SLAUGHTER_DATA_REPORT]: receiveSlaughterDataReport,
    [Types.REQUEST_MORE_SLAUGHTER_DATA_REPORT]: requestMoreSlaughterDataReport,
    [Types.RECEIVE_MORE_SLAUGHTER_DATA_REPORT]: receiveMoreSlaughterDataReport,
};

export default createReducer(INITIAL_STATE, HANDLERS);
