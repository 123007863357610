// @app/components/SlaughterPlan/duck/reducers.js
import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import { AnyAction } from "redux";

export type SlaughterPlanState = {
    slaughterPlans: any | null;
    isLoadingSlaughterPlans: boolean;
}

export const INITIAL_STATE: SlaughterPlanState = {
    slaughterPlans: [],
    isLoadingSlaughterPlans: false
};

export const requestSlaughterPlans = (state = INITIAL_STATE, action: AnyAction) => {
    return { ...state, isLoadingSlaughterPlans: true };
};

export const receiveSlaughterPlans = (state = INITIAL_STATE, action: AnyAction) => {
    const { slaughterPlans } = action;
    return { ...state, slaughterPlans: slaughterPlans, isLoadingSlaughterPlans: false };
};

export const HANDLERS = {
    [Types.REQUEST_SLAUGHTER_PLANS]: requestSlaughterPlans,
    [Types.RECEIVE_SLAUGHTER_PLANS]: receiveSlaughterPlans
};

export default createReducer(INITIAL_STATE, HANDLERS);
