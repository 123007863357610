import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import NavBarComponent from './NavBarComponent';
import { sidebarOperations } from '../Sidebar/duck';
import { notificationsOperations } from '../Notifications/duck';
import { appOperations } from '../../../duck';

const mapStateToProps = state => {
    const { config, isLoading, modules, selectedModule, selectedLang, menu, deviceType, navigationPath, perms } =
        state.app;
    const { authIdent } = state.auth;
    const { myProfile } = state.myPage;
    const { notifications, isNotificationsVisible, isLoadingNotifications } = state.notifications;
    const { isSidebarVisible } = state.sidebar;
    return {
        config,
        isLoading,
        modules,
        selectedModule,
        isSidebarVisible,
        notifications,
        isLoadingNotifications,
        selectedLang,
        authIdent,
        menu,
        deviceType,
        navigationPath,
        isNotificationsVisible,
        myProfile,
        perms,
    };
};

const mapDispatchToProps = dispatch => {
    const handleShowSidebar = () => {
        dispatch(sidebarOperations.handleShowSidebar());
    };
    const handleHideSidebar = () => {
        dispatch(sidebarOperations.handleHideSidebar());
    };
    const hideNotifications = () => {
        dispatch(notificationsOperations.hideNotifications());
    };
    const setSelectedLang = lang => {
        dispatch(appOperations.setSelectedLang(lang));
    };
    return {
        handleShowSidebar,
        handleHideSidebar,
        setSelectedLang,
        hideNotifications,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBarComponent));
