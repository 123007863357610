import React from 'react';
import { Popup, Icon, Table } from 'semantic-ui-react';
import '../phrase.css';

class PhraseTableHeaderCellComponent extends React.Component {
    render() {
        const { hasDescription } = this.props;
        if (hasDescription) {
            return this.renderWithDescription();
        }
        return this.renderWithoutDescription();
    }

    renderWithoutDescription = () => {
        return <React.Fragment>{this.renderHeaderCell()}</React.Fragment>;
    };

    renderWithDescription = () => {
        const { description } = this.props;
        return (
            <React.Fragment>
                <Popup trigger={this.renderHeaderCell()} content={description} />
            </React.Fragment>
        );
    };

    renderHeaderCell = () => {
        const { text, as, showEdit, className, sorted, onClick } = this.props;
        return (
            <Table.HeaderCell
                as={as}
                className={className ? `phrase-table-header-cell ${className}` : 'phrase-table-header-cell'}
                sorted={sorted}
                onClick={onClick}
            >
                {showEdit && this.renderEditIcon()}
                {text}
            </Table.HeaderCell>
        );
    };

    renderEditIcon = () => {
        return <Icon name="edit" className="phrase-edit-icon right" onClick={this.props.handleOpenEdit} />;
    };
}

export default PhraseTableHeaderCellComponent;
