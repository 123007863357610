import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Responsive, Segment, Icon, Feed, Message, Header, Divider } from 'semantic-ui-react';
import moment from 'moment';
import { PortalPhrase, PhraseParagraph } from '../../components';
import './style.css';
import { useSignalR } from './useSignalR';

const NotificationsComponent = ({
    fetchAllNotifications,
    markNotificationAsDismissed,
    markNotificationAsRead,
    notifications,
    isLoadingNotifications,
    isNotificationsVisible,
    updateReadNotifications,
    addNotification,
    hideNotifications,
    selectedLang,
}) => {
    const take = 50;
    const [skip, setSkip] = useState(0);

    const handleClick = useCallback(
        function handleClick(e) {
            if (isNotificationsVisible && !ReactDOM.findDOMNode(this).contains(e.target)) {
                hideNotifications();
            }
        },
        [hideNotifications, isNotificationsVisible]
    );

    useEffect(() => {
        fetchAllNotifications('nb', 0, skip + take);

        document.addEventListener('mousedown', handleClick, false);
        return document.removeEventListener('mousedown', handleClick, false);
    }, [fetchAllNotifications, handleClick, selectedLang]);

    // PrevProps hacks start
    const [show, setShow] = useState(isNotificationsVisible);
    useEffect(() => {
        if (isNotificationsVisible) {
            setShow(isNotificationsVisible);
        }

        if (show && !isNotificationsVisible) {
            const readNotifications = notifications.filter(item => !item.read).map(item => item.guid);
            if (notifications && readNotifications.length > 0) {
                notifications.forEach(notification => {
                    if (notification.status !== 'Read' && notification.status !== 'Dismissed') {
                        markNotificationAsRead(notification.id);
                    }
                });
            }
        }
    }, [isNotificationsVisible, notifications, show, updateReadNotifications]);
    //Prevprops hack end

    useSignalR(item => {
        try {
            const obj = JSON.parse(item);
            const lowerCaseKeys = Object.keys(obj).reduce((newObj, key) => {
                newObj[key.charAt(0).toLowerCase() + key.slice(1)] = obj[key];
                return newObj;
            }, {});

            if (!lowerCaseKeys.created) {
                lowerCaseKeys.created = new Date();
            }
            addNotification(lowerCaseKeys);
            setSkip(skip + 1);
        } catch (ex) {
            console.error('Error parsing or adding item:', item, ex);
        }
    });

    const handleMarkNotificationAsDismissed = notification => {
        markNotificationAsDismissed(notification.id);
    };

    const getDateRepresentation = dateTime => {
        const updated = moment.utc(dateTime).local();
        if (!updated.isValid()) {
            //Unrecognized format
            return renderTime({}, { defaultText: dateTime });
        }
        const currentDateTime = moment();
        if (currentDateTime.isBefore(updated)) {
            return 'NÅ';
        }
        if (currentDateTime.isSame(updated, 'minute')) {
            return `${currentDateTime.diff(updated, 'seconds')} sek`;
        }
        if (currentDateTime.isSame(updated, 'hour')) {
            return `${currentDateTime.diff(updated, 'minutes')} min`;
        }

        if (currentDateTime.isSame(updated, 'day')) {
            return `${currentDateTime.diff(updated, 'hours')} time${
                currentDateTime.diff(updated, 'hours') > 1 ? 'r' : ''
            }`;
        }

        return updated.format('D MMM');
    };

    const render = () => (
        <React.Fragment>
            <Responsive
                minWidth={Responsive.onlyTablet.maxWidth}
                fireOnMount
                aria-hidden={!isNotificationsVisible}
                className={isNotificationsVisible ? 'notifications-container' : 'notifications-container collapse'}
            >
                {renderContent()}
            </Responsive>
            <Responsive
                maxWidth={Responsive.onlyTablet.maxWidth}
                fireOnMount
                aria-hidden={!isNotificationsVisible}
                className={
                    isNotificationsVisible
                        ? 'notifications-container mobile'
                        : 'notifications-container mobile collapse'
                }
            >
                {renderContent()}
            </Responsive>
        </React.Fragment>
    );

    const renderContent = () => {
        return (
            <Segment.Group style={{ height: '100%', display: 'flex' }}>
                <Segment style={{ display: 'flex' }}>
                    <Header as="h2" style={{ flex: 1 }}>
                        Varslinger
                    </Header>
                    <Icon name="close" onClick={hideNotifications} link />
                </Segment>
                <Segment loading={isLoadingNotifications} style={{ flex: 1, overflowY: 'auto' }}>
                    {!notifications || notifications.length < 1 ? (
                        <Message header="Ingen varslinger" content="Du har ingen aktive varslinger" />
                    ) : (
                        <Feed>{notifications.map((n, i) => renderNotification(n, i))}</Feed>
                    )}
                </Segment>
            </Segment.Group>
        );
    };

    const renderNotification = (notification, key) => {
        return (
            <React.Fragment key={key}>
                <Feed.Event style={{ flex: '1' }}>
                    <Feed.Content>
                        <Feed.Summary>
                            {notification.subject}
                            <Feed.Date>{getDateRepresentation(notification.created)}</Feed.Date>
                            <Icon
                                name="trash"
                                onClick={() => handleMarkNotificationAsDismissed(notification)}
                                link
                                style={{ float: 'right' }}
                            />
                        </Feed.Summary>
                        <Feed.Extra text>{notification.text}</Feed.Extra>
                        <Feed.Meta>
                            <Feed.Like>
                                {notification.status === 'Read' ? (
                                    <>
                                        <Icon
                                            name="bell outline"
                                            style={{
                                                color: 'var(--material-gray-5)',
                                            }}
                                        />
                                        Lest
                                    </>
                                ) : (
                                    <>
                                        <Icon
                                            name="bell"
                                            style={{
                                                color: 'var(--primary-color)',
                                            }}
                                        />
                                        Ny!
                                    </>
                                )}
                            </Feed.Like>
                        </Feed.Meta>
                    </Feed.Content>
                </Feed.Event>
                <Divider />
            </React.Fragment>
        );
    };

    const renderTime = (text1, { defaultText, guid }) => {
        return (
            <React.Fragment>
                <PortalPhrase defaultText={text1.defaultText} guid={text1.guid} hasText>
                    <PhraseParagraph
                        style={{
                            fontWeight: 'bold',
                            margin: '0',
                            fontSize: '2em',
                            lineHeight: '1',
                        }}
                    />
                </PortalPhrase>
                <PortalPhrase defaultText={defaultText} guid={guid} hasText>
                    <PhraseParagraph
                        style={{
                            margin: '0',
                            fontSize: '1.1em',
                            lineHeight: '1',
                        }}
                    />
                </PortalPhrase>
            </React.Fragment>
        );
    };

    return render();
};

export default NotificationsComponent;
