import React, { createContext, useEffect, useState } from 'react';

import SignalRConnection from './SignalRConnection';

const SignalRContext = createContext(null);

function SignalRProvider({ children }) {
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        const client = new SignalRConnection();
        setConnection(client);

        return () => client.close();
    }, []);

    return <SignalRContext.Provider value={connection}>{children}</SignalRContext.Provider>;
}

export { SignalRContext, SignalRProvider };
