import { apiFetch } from '../../../utility';
const { detect } = require('detect-browser');
const browser = detect();

export function getMicrosoftConfig(dispatch) {
    const url = `api/Auth/GetMicrosoftConfig`;
    return apiFetch(dispatch, url, null);
}

export function getProdRegConfig(dispatch) {
    const url = `api/Auth/GetProdRegConfig`;
    return apiFetch(dispatch, url, null);
}

export function getAuthIdent(dispatch, ident, isPersistent) {
    const url = `api/Auth/GetAuthIdent?ident=${ident}&isPersistent=${isPersistent}`;
    return apiFetch(dispatch, url, null);
}

export const setAuthToken = (dispatch, token) => {
    const authToken = {
        token: token,
        browser: browser,
    };
    const options = {
        method: 'post',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(authToken),
    };
    const url = `api/Auth/SetAuthToken`;
    return apiFetch(dispatch, url, options, true);
};

export const loginVeniCloud = (dispatch, ident, password, isPersistent) => {
    const url = `api/Auth/LoginVeniCloud?ident=${ident}&password=${password}&isPersistent=${isPersistent === true}`;
    return apiFetch(dispatch, url);
};

export function logout(dispatch) {
    const url = `api/Auth/Logout`;
    return apiFetch(dispatch, url);
}
