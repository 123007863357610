import _ from 'lodash';

export const validateAuthVeniCloud = ident => {
    return new Promise(function (resolve, reject) {
        return fetch(`api/Auth/ValidateAuthVeniCloud?ident=${ident}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                return null;
            })
            .then(result => {
                return resolve(result);
            })
            .catch(error => {
                return reject(error.message);
            });
    });
};

export const loginVeniCloud = (ident, password, isPersistent) => {
    return new Promise(function (resolve, reject) {
        const url = `api/Auth/LoginVeniCloud?ident=${ident}&password=${password}&isPersistent=${isPersistent}`;
        return fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                return null;
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error.message);
            });
    });
};

export const logoutVeniCloud = () => {
    return fetch(`api/Auth/LogoutVeniCloud`).then(result => result.json());
};

export const fetchVeniCloud = (url, options) => {
    return fetch(url, options);
};
