// duck/operations.js
import { Creators } from './actions';
import {
    getAuthIdent,
    getVersion,
    getPortalConfig,
    getAppContext,
    getAppContextSimplified,
    getGlobalPhrases,
    saveGlobalPhrase,
    getPortalPhrases,
    savePortalPhrase,
    getModulePhrases,
    saveModulePhrase,
    getProducerInfo,
} from './api';
import _ from 'lodash';

const handleMessageAction = Creators.appHandleMessage;
const removeMessageAction = Creators.appRemoveMessage;
const requestAuthIdentAction = Creators.appRequestAuthIdent;
const receiveAuthIdentAction = Creators.appReceiveAuthIdent;
const requestVersionAction = Creators.appRequestVersion;
const receiveVersionAction = Creators.appReceiveVersion;
const requestPortalConfigAction = Creators.appRequestPortalConfig;
const receivePortalConfigAction = Creators.appReceivePortalConfig;
const requestAppContextAction = Creators.appRequestAppContext;
const requestAppContextSimplifiedAction = Creators.appRequestAppContextSimplified;
const receiveAppContextAction = Creators.appReceiveAppContext;
const receiveAppContextSimplifiedAction = Creators.appReceiveAppContextSimplified;
const setSelectedModuleAction = Creators.appSetSelectedModule;
const setSelectedSubModuleAction = Creators.appSetSelectedSubModule;
const requestGlobalPhrasesAction = Creators.appRequestGlobalPhrases;
const receiveGlobalPhrasesAction = Creators.appReceiveGlobalPhrases;
const requestSaveGlobalPhraseAction = Creators.appRequestSaveGlobalPhrase;
const receiveSaveGlobalPhraseAction = Creators.appReceiveSaveGlobalPhrase;
const requestPortalPhrasesAction = Creators.appRequestPortalPhrases;
const receivePortalPhrasesAction = Creators.appReceivePortalPhrases;
const requestSavePortalPhraseAction = Creators.appRequestSavePortalPhrase;
const receiveSavePortalPhraseAction = Creators.appReceiveSavePortalPhrase;
const requestModulePhrasesAction = Creators.appRequestModulePhrases;
const receiveModulePhrasesAction = Creators.appReceiveModulePhrases;
const requestSaveModulePhraseAction = Creators.appRequestSaveModulePhrase;
const receiveSaveModulePhraseAction = Creators.appReceiveSaveModulePhrase;
const setDeviceTypeAction = Creators.appSetDeviceType;
const setSelectedLangAction = Creators.appSetSelectedLang;
const setPhraseEditModeAction = Creators.appSetPhraseEditMode;
const addNavigationPathElementAction = Creators.appAddNavigationPathElement;
const setNavigationPathAction = Creators.appSetNavigationPath;
const setShowReturnButtonAction = Creators.appSetShowReturnButton;
const requestProducerInfoAction = Creators.appRequestProducerInfo;
const receiveProducerInfoAction = Creators.appReceiveProducerInfo;

const fetchAuthIdent = (ident, isPersistent) => {
    return dispatch => {
        dispatch(requestAuthIdentAction(ident));
        getAuthIdent(dispatch, ident, isPersistent).then(result => {
            if (result) {
                dispatch(receiveAuthIdentAction(result));
            } else {
                dispatch(handleMessageAction(_.get(result, 'errorMessage') || 'Failed to fetch auth identity'));
            }
        });
    };
};

const fetchVersion = () => {
    return dispatch => {
        dispatch(requestVersionAction());
        getVersion(dispatch).then(result => {
            if (result && !result.isError) {
                dispatch(receiveVersionAction(result.value));
            } else {
                dispatch(handleMessageAction(_.get(result, 'errorMessage') || 'Failed to fetch app version'));
            }
        });
    };
};

const fetchPortalConfig = () => {
    return dispatch => {
        dispatch(requestPortalConfigAction());
        getPortalConfig(dispatch).then(result => {
            if (result) {
                dispatch(receivePortalConfigAction(result));
            } else {
                dispatch(handleMessageAction(_.get(result, 'errorMessage') || 'Failed to fetch portal config'));
            }
        });
    };
};

const fetchAppContext = () => {
    return dispatch => {
        dispatch(requestAppContextAction());
        getAppContext(dispatch).then(result => {
            if (result) {
                dispatch(receiveAppContextAction(result));
            } else {
                dispatch(handleMessageAction(_.get(result, 'errorMessage') || 'Failed to fetch app context'));
            }
        });
    };
};

const fetchAppContextSimplified = () => {
    return dispatch => {
        dispatch(requestAppContextSimplifiedAction());
        getAppContextSimplified(dispatch).then(result => {
            if (result) {
                dispatch(receiveAppContextSimplifiedAction(result));
            } else {
                dispatch(
                    handleMessageAction(_.get(result, 'errorMessage') || 'Failed to fetch app context simplified')
                );
            }
        });
    };
};

const setSelectedModule = module => {
    return dispatch => {
        dispatch(setSelectedModuleAction(module));
    };
};

const setSelectedSubModule = subModule => {
    return dispatch => {
        dispatch(setSelectedSubModuleAction(subModule));
    };
};

const fetchGlobalPhrases = lang => {
    return dispatch => {
        dispatch(requestGlobalPhrasesAction());
        getGlobalPhrases(dispatch, lang).then(result => {
            if (result) {
                dispatch(receiveGlobalPhrasesAction(result.items));
            } else {
                dispatch(handleMessageAction(_.get(result, 'errorMessage') || 'Failed to fetch global phrases'));
            }
        });
    };
};

const fetchPortalPhrases = lang => {
    return dispatch => {
        dispatch(requestPortalPhrasesAction());
        getPortalPhrases(dispatch, lang).then(result => {
            if (result) {
                dispatch(receivePortalPhrasesAction(result.items));
            } else {
                dispatch(handleMessageAction(_.get(result, 'errorMessage') || 'Failed to fetch portal phrases'));
            }
        });
    };
};

const fetchModulePhrases = (moduleIdent, lang) => {
    return dispatch => {
        dispatch(requestModulePhrasesAction());
        getModulePhrases(dispatch, moduleIdent, lang).then(result => {
            if (result) {
                dispatch(receiveModulePhrasesAction(moduleIdent, result.items));
            } else {
                dispatch(handleMessageAction(_.get(result, 'errorMessage') || 'Failed to fetch module phrases'));
            }
        });
    };
};

const setDeviceType = deviceType => {
    return dispatch => {
        dispatch(setDeviceTypeAction(deviceType));
    };
};

const doSaveGlobalPhrase = (moduleId, lang, phrases) => {
    return dispatch => {
        dispatch(requestSaveGlobalPhraseAction(moduleId, lang, phrases));
        saveGlobalPhrase(dispatch, moduleId, lang, phrases).then(result => {
            if (result && !result.isError) {
                dispatch(receiveSaveGlobalPhraseAction());
            } else {
                dispatch(handleMessageAction(_.get(result, 'errorMessage') || 'Failed to save phrase', 'error'));
            }
        });
    };
};

const doSavePortalPhrase = (moduleId, lang, phrases) => {
    return dispatch => {
        dispatch(requestSavePortalPhraseAction(moduleId, lang, phrases));
        savePortalPhrase(dispatch, moduleId, lang, phrases).then(result => {
            if (result && !result.isError) {
                dispatch(receiveSavePortalPhraseAction());
            } else {
                dispatch(handleMessageAction(_.get(result, 'errorMessage') || 'Failed to save phrase', 'error'));
            }
        });
    };
};

const doSaveModulePhrase = (moduleId, lang, phrases) => {
    return dispatch => {
        dispatch(requestSaveModulePhraseAction(moduleId, lang, phrases));
        saveModulePhrase(dispatch, moduleId, lang, phrases).then(result => {
            if (result && !result.isError) {
                dispatch(receiveSaveModulePhraseAction());
            } else {
                dispatch(handleMessageAction(_.get(result, 'errorMessage') || 'Failed to save phrase', 'error'));
            }
        });
    };
};

const setSelectedLang = lang => {
    return dispatch => {
        dispatch(setSelectedLangAction(lang));
    };
};

const setPhraseEditMode = isEdit => {
    return dispatch => {
        dispatch(setPhraseEditModeAction(isEdit));
    };
};

const removeMessage = guid => {
    return dispatch => {
        dispatch(removeMessageAction(guid));
    };
};

const addNavigationPathElement = element => {
    return dispatch => {
        dispatch(addNavigationPathElementAction(element));
    };
};

const setNavigationPath = path => {
    return dispatch => {
        dispatch(setNavigationPathAction(path));
    };
};

const setShowReturnButton = show => {
    return dispatch => {
        dispatch(setShowReturnButtonAction(show));
    };
};

const fetchProducerInfo = moduleIdent => {
    return dispatch => {
        dispatch(requestProducerInfoAction());
        getProducerInfo(moduleIdent).then(result => {
            if (result) {
                dispatch(receiveProducerInfoAction(result));
            } else {
                dispatch(
                    handleMessageAction(_.get(result, 'errorMessage') || 'Failed to fetch app producer info')
                );
            }
        })
    }
}

export default {
    removeMessage,
    fetchAuthIdent,
    fetchVersion,
    fetchPortalConfig,
    fetchAppContext,
    fetchAppContextSimplified,
    setSelectedModule,
    setSelectedSubModule,
    fetchGlobalPhrases,
    doSaveGlobalPhrase,
    fetchPortalPhrases,
    doSavePortalPhrase,
    fetchModulePhrases,
    doSaveModulePhrase,
    setDeviceType,
    setSelectedLang,
    setPhraseEditMode,
    addNavigationPathElement,
    setNavigationPath,
    setShowReturnButton,
    fetchProducerInfo,
};
