// duck/reducers.js
import { createReducer } from 'reduxsauce';
import _ from 'lodash';
import { Types } from './actions';

export const INITIAL_STATE = {
    ident: null,
    authIdent: null,
    isLoadingAuthIdent: false,
    token: null,
    isAuthenticated: false,
    isLoggingIn: false,
    isLoggingOut: false,
    loginFailed: false,
    isUnauthorized: false,
    isValidating: false,
    wasNotFound: false,
};

export const requestAuthIdent = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        token: null,
        isAuthenticated: false,
        isLoadingAuthIdent: true,
    };
};

export const receiveAuthIdent = (state = INITIAL_STATE, action) => {
    const { authIdent } = action;
    return {
        ...state,
        authIdent: authIdent,
        isLoadingAuthIdent: false,
        isUnauthorized: _.isNull(authIdent),
    };
};

export const loginStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoggingIn: true,
        isAuthenticated: false,
        token: null,
        isUnauthorized: false,
        loginFailed: false,
    };
};

export const loginCanceled = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginFailed: false,
    };
};

export const loginSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isAuthenticated: true,
        isLoggingIn: false,
        isUnauthorized: false,
        loginFailed: false,
    };
};

export const loginFailed = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isAuthenticated: false,
        isLoggingIn: false,
        token: null,
        isUnauthorized: true,
        loginFailed: true,
    };
};

export const logoutStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoggingIn: false,
        isLoggingOut: true,
    };
};

export const logoutSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        ident: null,
        authIdent: null,
        isAuthenticated: false,
        isUnauthorized: true,
        isLoggingIn: false,
        isLoggingOut: false,
        token: null,
    };
};

export const wasNotFound = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        wasNotFound: state.authIdent.wasNotFound,
    };
};

export const logoutFailed = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        ident: null,
        authIdent: null,
        isAuthenticated: false,
        isUnauthorized: true,
        isLoggingIn: false,
        isLoggingOut: false,
        token: null,
    };
};

export const validateAuthStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isValidating: true,
    };
};

export const validateAuthSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isAuthenticated: true,
        isValidating: false,
    };
};

export const validateAuthFailed = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isAuthenticated: false,
        isValidating: false,
    };
};

export const HANDLERS = {
    [Types.REQUEST_AUTH_IDENT]: requestAuthIdent,
    [Types.RECEIVE_AUTH_IDENT]: receiveAuthIdent,
    [Types.LOGIN_START]: loginStart,
    [Types.LOGIN_CANCELED]: loginCanceled,
    [Types.LOGIN_SUCCESS]: loginSuccess,
    [Types.LOGIN_FAILED]: loginFailed,
    [Types.LOGOUT_START]: logoutStart,
    [Types.LOGOUT_SUCCESS]: logoutSuccess,
    [Types.LOGOUT_FAILED]: logoutFailed,
    [Types.WAS_NOT_FOUND]: wasNotFound,
    [Types.VALIDATE_AUTH_START]: validateAuthStart,
    [Types.VALIDATE_AUTH_SUCCESS]: validateAuthSuccess,
    [Types.VALIDATE_AUTH_FAILED]: validateAuthFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
