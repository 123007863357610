import { push } from 'connected-react-router';
import _ from 'lodash';
import { validateAuthMicrosoft } from '../common/Auth/duck/authMicrosoft';
import { validateAuthVeniCloud } from '../common/Auth/duck/authVeniCloud';
import { validateAuthProdReg } from '../common/Auth/duck/authProdReg';

export const apiFetch = (dispatch, url, options, redirectOnError, isRetry) => {
    if (isRetry) {
        if (!options) {
            options = {};
        }
        options.cache = 'reload';
    }
    return fetch(url, options)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                console.error('API_ERROR', response);
                if (response.status === 404 && redirectOnError) {
                    dispatch(push('/NoAccess'));
                }
                if (response.status === 401) {
                    if (!isRetry) {
                        return fetch(`api/Auth/GetAuthIdent`)
                            .then(r => r.json())
                            .then(authIdent => {
                                if (authIdent && !authIdent.isError) {
                                    switch (_.toLower(authIdent.type)) {
                                        case 'venicloud': {
                                            return validateAuthVeniCloud(authIdent.ident)
                                                .then(loginResult => {
                                                    if (loginResult && !loginResult.isError) {
                                                        return apiFetch(dispatch, url, options, redirectOnError, true);
                                                    } else {
                                                        dispatch(push('/signout'));
                                                        return null;
                                                    }
                                                })
                                                .catch(() => {
                                                    dispatch(push('/signout'));
                                                });
                                        }
                                        case 'prodreg': {
                                            return validateAuthProdReg(authIdent.ident)
                                                .then(loginResult => {
                                                    if (loginResult && !loginResult.isError) {
                                                        return apiFetch(dispatch, url, options, redirectOnError, true);
                                                    } else {
                                                        dispatch(push('/signout'));
                                                        return null;
                                                    }
                                                })
                                                .catch(() => {
                                                    dispatch(push('/signout'));
                                                });
                                        }
                                        case 'microsoft': {
                                            return validateAuthMicrosoft(authIdent.ident)
                                                .then(loginResult => {
                                                    if (loginResult && !loginResult.isError) {
                                                        return apiFetch(dispatch, url, options, redirectOnError, true);
                                                    } else {
                                                        dispatch(push('/signout'));
                                                        return null;
                                                    }
                                                })
                                                .catch(() => {
                                                    dispatch(push('/signout'));
                                                });
                                        }
                                        default: {
                                            console.error(`${authIdent.type} is not a valid authenticator!`);
                                            dispatch(push('/signout'));
                                        }
                                    }
                                } else {
                                    dispatch(push('/signout'));
                                }
                            })
                            .catch(() => {
                                dispatch(push('/signout'));
                            });
                    } else {
                        dispatch(push('/signout'));
                    }
                } else {
                    if (redirectOnError) {
                        console.error('ERROR', response.status);
                        dispatch(push(`/error?errorMessage=${response.status}`));
                    }
                }
                return null;
            }
        })
        .then(result => {
            if (_.get(result, 'isError')) {
                console.error('ERROR', result);
                if (redirectOnError) {
                    const errorMessage = result.errorMessage
                        ? `?errorMessage=${encodeURIComponent(result.errorMessage)}`
                        : null;
                    dispatch(push(`/error${errorMessage}`));
                }
            }
            return result;
        })
        .catch(error => {
            console.error(error);
            if (redirectOnError) {
                dispatch(push(`/error?errorMessage=${encodeURIComponent(error.message)}`));
            }
            return null;
        });
};
