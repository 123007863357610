import React from 'react';
import _ from 'lodash';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import './general.css';
import moment from 'moment';
import 'moment/locale/nb';

class RegistrationDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
    }

    handleModalClick = e => {
        const { modalOpen } = this.state;
        this.setState({ modalOpen: !modalOpen });
    };

    handleCalendarOnChange = (event, { name, value }) => {
        const { valueSet, setValue } = this.props;
        setValue && setValue(value);
        valueSet && value && valueSet();
        this.setState({ modalOpen: false });
    };

    render = () => {
        const { value, disable, enable, minDate, maxDate, markColor, localization } = this.props;
        return (
            <Modal
                trigger={
                    <Button
                        style={{
                            paddingRight: '0.5em',
                            paddingLeft: '1em',
                            background: 'none',
                            border: '2px solid rgba(28, 31, 42, 0.3)',
                            borderRadius: '0.4',
                            color: 'var(--secondary-color)',
                            opacity: '0.6',
                        }}
                        onClick={this.handleModalClick}
                        className="date-button"
                    >
                        <Icon name="calendar alternate outline" />{' '}
                        {value ? moment(value).format('Do MMM YYYY') : 'Trykk for å velge'}{' '}
                        <Icon name="angle down" style={{ marginLeft: '0.5em' }} />
                    </Button>
                }
                style={{ width: 'auto' }}
                open={this.state.modalOpen}
                onClose={this.handleModalClick}
            >
                <DateInput
                    name="calendarDate"
                    inline
                    value={value}
                    dateFormat="YYYY-MM-DD"
                    onChange={this.handleCalendarOnChange}
                    initialDate={minDate}
                    localization={localization}
                    disable={disable}
                    enable={enable}
                    minDate={minDate}
                    maxDate={maxDate}
                    markColor={markColor}
                    hideMobileKeyboard={true}
                    pickerStyle={{ minWidth: '0em', maxWidth: '100%' }}
                />
            </Modal>
        );
    };
}
export default RegistrationDate;
