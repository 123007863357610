import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    notificationsShowNotifications: [],
    notificationsHideNotifications: [],
    notificationsRequestNotifications: [],
    notificationsReceiveNotifications: ['notifications'],
    notificationsMarkNotificationAsRead: ['id'],
    notificationsMarkNotificationAsDismissed: ['id'],
    notificationsReceiveNotification: ['notification'],
});

export { Creators, Types };
