import React from 'react';
import { withRouter } from 'react-router';
import {} from 'semantic-ui-react';

class NotFoundComponent extends React.Component {
    render() {
        return (
            <div>
                <h2>Path not found!</h2>
            </div>
        );
    }
}

export default withRouter(NotFoundComponent);
