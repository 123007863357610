import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';

const Option = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.35em 0.7em;
    padding-bottom: 0;
    background: none;
    border: 0;
    transition: all 0.25s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`;

const Img = styled(Image)`
    &&&& {
        height: 2.5em;
        width: auto;
        object-fit: contain;
        filter: ${props => (props.active ? 'grayscale(0)' : 'grayscale(1)')};
    }
`;

const AnimalTypeButton = props => {
    return (
        <Fragment>
            <Option
                isSelected={props.isSelected}
                onClick={() => props.onGetFilterItem(props.name === props.isSelected ? null : props.name)}
            >
                <Img
                    size="tiny"
                    src={props.url}
                    alt="animal-type"
                    active={+props.isSelected}
                    onClick={props.onClickAnimalType}
                />
            </Option>
        </Fragment>
    );
};

export default AnimalTypeButton;
