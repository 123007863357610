import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { authOperations } from './duck';
import { appOperations } from '../../../duck';
import LoginComponent from './LoginComponent';

const mapStateToProps = state => {
    const { config, isLoadingConfig, hasLoadedPortalPhrases, selectedLang } = state.app;
    const { isAuthenticated, authIdent, isLoadingAuthIdent, isLoggingIn, loginFailed, isValidating, wasNotFound } = state.auth;
    return {
        config,
        isLoadingConfig,
        hasLoadedPortalPhrases,
        isAuthenticated,
        authIdent,
        isLoadingAuthIdent,
        isLoggingIn,
        loginFailed,
        isValidating,
        selectedLang,
        wasNotFound,
    };
};

const mapDispatchToProps = dispatch => {
    const fetchAuthIdent = (ident, isPersistent) => {
        return dispatch(authOperations.fetchAuthIdent(ident, isPersistent));
    };
    const validateAuth = (authIdent, code) => {
        dispatch(authOperations.doValidateAuth(authIdent, code));
    };
    const doLogin = (authIdent, password, isPersistent) => {
        dispatch(authOperations.doLogin(authIdent, password, isPersistent));
    };
    const doLogout = auth => {
        dispatch(authOperations.doLogout(auth));
    };
    const fetchGlobalPhrases = lang => {
        dispatch(appOperations.fetchGlobalPhrases(lang));
    };
    const fetchPortalPhrases = lang => {
        dispatch(appOperations.fetchPortalPhrases(lang));
    };
    return { fetchAuthIdent, validateAuth, doLogin, doLogout, fetchGlobalPhrases, fetchPortalPhrases };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginComponent));
