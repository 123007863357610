import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { ModulePhrase, PhraseInput, PhraseParagraph } from '..';
import { Icon, Button, Dropdown, Table } from 'semantic-ui-react';
import './general.css';

export default function FilterDropdownComponent({
    isAlphabetic,
    isAsc,
    handleSort,
    parentFilter,
    activeParents,
    setActiveParents,
    parents,
    handleParentClick,
    scale,
}) {
    function renderDropdown() {
        return (
            <Dropdown
                item
                className="filter-dropdown"
                style={(scale && { transform: `scale(${scale})` }) || {}}
                trigger={
                    <Button.Group fluid icon className="filter-dropdown-buttons">
                        <Button className="filter-dropdown-button-left">
                            <Icon name="filter" />
                        </Button>
                        <Button className="filter-dropdown-button-right">
                            <Icon name="dropdown" />
                        </Button>
                    </Button.Group>
                }
                icon={null}
            >
                <Dropdown.Menu style={(scale && { transform: `scale(${1 / scale})` }) || {}}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop: '4px',
                        }}
                    >
                        <Dropdown.Header>
                            {isAlphabetic && isAsc ? (
                                <Button
                                    onClick={() => handleSort && handleSort(true, false)}
                                    icon="sort alphabet down"
                                    label={{
                                        as: 'button',
                                        icon: {
                                            name: 'sort numeric down',
                                            className: 'filter-dropdown-sort-label-icon',
                                        },
                                        onClick: () => handleSort && handleSort(false, true),
                                        pointing: 'left',
                                        className: 'filter-dropdown-sort-label',
                                    }}
                                    labelPosition="right"
                                    className="filter-dropdown-sort-button"
                                />
                            ) : isAlphabetic ? (
                                <Button
                                    onClick={() => handleSort && handleSort(true, true)}
                                    icon="sort alphabet up"
                                    label={{
                                        as: 'button',
                                        icon: {
                                            name: 'sort numeric down',
                                            className: 'filter-dropdown-sort-label-icon',
                                        },
                                        onClick: () => handleSort && handleSort(false, true),
                                        pointing: 'left',
                                        className: 'filter-dropdown-sort-label',
                                    }}
                                    labelPosition="right"
                                    className="filter-dropdown-sort-button"
                                />
                            ) : isAsc ? (
                                <Button
                                    onClick={() => handleSort && handleSort(false, false)}
                                    icon="sort numeric down"
                                    label={{
                                        as: 'button',
                                        icon: {
                                            name: 'sort alphabet down',
                                            className: 'filter-dropdown-sort-label-icon',
                                        },
                                        onClick: () => handleSort && handleSort(true, true),
                                        pointing: 'right',
                                        className: 'filter-dropdown-sort-label',
                                    }}
                                    labelPosition="left"
                                    className="filter-dropdown-sort-button"
                                />
                            ) : (
                                <Button
                                    onClick={() => handleSort && handleSort(false, true)}
                                    icon="sort numeric up"
                                    label={{
                                        as: 'button',
                                        icon: {
                                            name: 'sort alphabet down',
                                            className: 'filter-dropdown-sort-label-icon',
                                        },
                                        onClick: () => handleSort && handleSort(true, true),
                                        pointing: 'right',
                                        className: 'filter-dropdown-sort-label',
                                    }}
                                    labelPosition="left"
                                    className="filter-dropdown-sort-button"
                                />
                            )}
                            {/*
                        <span>
                        <ModulePhrase
                            defaultText='Date'
                            guid='d9ae3e2c-4f96-4367-8037-12bd7d3299b3'
                            hasText>
                            <PhraseParagraph />
                        </ModulePhrase>
                        <ModulePhrase
                        defaultText='Alfabetisk'
                            guid='4ae72c0c-6d71-42e7-9c64-34c91df62a19'
                            hasText>
                            <PhraseParagraph />
                        </ModulePhrase>
                        </span>
                        */}
                        </Dropdown.Header>
                        {parentFilter && renderParentFilter()}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    function renderParentFilter() {
        const isAll = _.isEmpty(activeParents);
        return (
            <React.Fragment>
                <Dropdown.Divider />
                <ModulePhrase defaultText="Include in view" guid="28c3b2d9-c475-43ec-9fd1-ba3a81e4434c" hasText>
                    <PhraseParagraph style={{ textAlign: 'center', marginBottom: '0' }} />
                </ModulePhrase>
                <Table structured style={{ marginTop: '0' }}>
                    <Table.Body>
                        <Table.Row active={isAll} onClick={() => setActiveParents && setActiveParents([])} style={{}}>
                            <Table.Cell className="filter-dropdown-filter-cell" style={{ paddingRight: 0 }}>
                                {isAll && <Icon name="check" color="green" />}
                            </Table.Cell>
                            <Table.Cell className="filter-dropdown-filter-cell" style={{ paddingLeft: 0 }}>
                                <ModulePhrase defaultText="All" guid="618a7161-5343-4415-9eec-5188657bb37b" hasText>
                                    <PhraseParagraph />
                                </ModulePhrase>
                            </Table.Cell>
                        </Table.Row>
                        {_.map(parents, renderParent)}
                    </Table.Body>
                </Table>
            </React.Fragment>
        );
    }

    function renderParent(parent) {
        const isActive = _.some(activeParents, item => item === parent);
        return (
            <Table.Row
                key="parent"
                active={isActive}
                onClick={() => handleParentClick && handleParentClick(parent, isActive)}
            >
                <Table.Cell className="filter-dropdown-filter-cell" style={{ paddingRight: 0 }}>
                    {isActive && <Icon name="check" color="green" />}
                </Table.Cell>
                <Table.Cell className="filter-dropdown-filter-cell" style={{ paddingLeft: 0 }}>
                    {parent}
                </Table.Cell>
            </Table.Row>
        );
    }

    return renderDropdown();
}
