import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import _ from 'lodash';

export const INITIAL_STATE = {
    registeredRegistrations: [],
    isLoadingRegistrations: false,
    isLoadingMoreRegistrations: false,
    producerInfo: null,
    isLoadingProducerInfo: false,
    preparedRegistrations: [],
    isSendingRegistrations: false,
    savedRegistrations: [],
    isLoadingLivestock: false,
    isLoadingMoreLivestock: false,
    isSavingRegistrations: false,
    livestock: [],
    isLoadingExpandedRegistration: false,
    expandedRegistration: [],
    enrollmentConfiguration: null,
    isLoadingEnrollmentConfiguration: false
};

export const requestRegistrations = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingRegistrations: true,
    };
};

export const receiveRegistrations = (state = INITIAL_STATE, action) => {
    const { registrations } = action;
    return {
        ...state,
        requestedRegistrations: registrations,
        isLoadingRegistrations: false,
    };
};

export const requestMoreRegistrations = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingMoreRegistrations: true,
    };
};

export const receiveMoreRegistrations = (state = INITIAL_STATE, action) => {
    const { moreRegistrations } = action;
    const { requestedRegistrations } = state;
    return {
        ...state,
        requestedRegistrations: [...requestedRegistrations, ...moreRegistrations],
        isLoadingMoreRegistrations: false,
    };
};

export const addRegistration = (state = INITIAL_STATE, action) => {
    const { registration } = action;
    const { preparedRegistrations } = state;
    return {
        ...state,
        preparedRegistrations: [...preparedRegistrations, registration],
        savedRegistrations: [],
    };
};

export const updateRegistrations = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        preparedRegistrations: action.registrations,
    };
};

export const removeRegistration = (state = INITIAL_STATE, action) => {
    const { index } = action;
    const { preparedRegistrations } = state;
    return {
        ...state,
        preparedRegistrations: _.flatten([
            _.take(preparedRegistrations, index),
            _.drop(preparedRegistrations, index + 1),
        ]),
    };
};

export const requestProducerInfo = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingProducerInfo: true,
    };
};

export const receiveProducerInfo = (state = INITIAL_STATE, action) => {
    const { producerInfo } = action;
    return {
        ...state,
        producerInfo: producerInfo,
        isLoadingProducerInfo: false,
    };
};

export const requestSaveRegistrations = (state = INITIAL_STATE) => {
    return {
        ...state,
        isSavingRegistrations: true,
    };
};

export const receiveSaveRegistrations = (state = INITIAL_STATE, action) => {
    const { savedRegistrations } = action;
    const { preparedRegistrations } = state;
    return {
        ...state,
        savedRegistrations: savedRegistrations,
        preparedRegistrations: _.filter(preparedRegistrations, (item, i) => savedRegistrations[i].isError),
        isSavingRegistrations: false,
    };
};

export const requestLivestock = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingLivestock: true,
    };
};

const sortFunction = ({ item: cattleA }, { item: cattleB }) => {
    const brandA = Number.parseInt(cattleA.id_brand.substring(cattleA.id_brand.length - 4));
    const brandB = Number.parseInt(cattleB.id_brand.substring(cattleA.id_brand.length - 4));
    return brandA - brandB;
};

const mapCattle = elem => {
    return {
        ...elem,
        item: {
            ...elem.item,
            brandString: `${elem.item.id_brand.substring(
                0,
                elem.item.id_brand.length - 4
            )} - ${elem.item.id_brand.substring(elem.item.id_brand.length - 4)}`,
        },
    };
};

export const receiveLivestock = (state = INITIAL_STATE, action) => {
    const { livestock } = action;
    const items = livestock.items.map(mapCattle).sort(sortFunction);
    return {
        ...state,
        isLoadingLivestock: false,
        livestock: items,
    };
};

export const requestMoreLivestock = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingMoreLivestock: true,
    };
};

export const receiveMoreLivestock = (state = INITIAL_STATE, action) => {
    //This should never be called since we use such a high take param
    const { moreLivestock } = action;
    const { livestock } = state;
    const newLivestock = moreLivestock.items.map(mapCattle);
    const newItems = [...livestock, ...newLivestock].sort(sortFunction);
    return {
        ...state,
        isLoadingMoreLivestock: false,
        livestock: newItems,
    };
};

export const requestExpandedRegistration = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingExpandedRegistration: true,
    };
};

export const receiveExpandedRegistration = (state = INITIAL_STATE, action) => {
    const { expandedRegistrationDetails } = action;
    return {
        ...state,
        expandedRegistrationDetails: expandedRegistrationDetails,
        isLoadingExpandedRegistration: false,
    };
};

export const requestEnrollmentConfiguration = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingEnrollmentConfiguration: true,
    };
};

export const receiveEnrollmentConfiguration = (state = INITIAL_STATE, action) => {
    const { enrollmentConfiguration } = action;
    return {
        ...state,
        enrollmentConfiguration: enrollmentConfiguration.items,
        isLoadingEnrollmentConfiguration: false,
    };
};


export const HANDLERS = {
    [Types.REQUEST_REGISTRATIONS]: requestRegistrations,
    [Types.RECEIVE_REGISTRATIONS]: receiveRegistrations,
    [Types.REQUEST_MORE_REGISTRATIONS]: requestMoreRegistrations,
    [Types.RECEIVE_MORE_REGISTRATIONS]: receiveMoreRegistrations,
    [Types.ADD_REGISTRATION]: addRegistration,
    [Types.UPDATE_REGISTRATIONS]: updateRegistrations,
    [Types.REMOVE_REGISTRATION]: removeRegistration,
    [Types.REQUEST_PRODUCER_INFO]: requestProducerInfo,
    [Types.RECEIVE_PRODUCER_INFO]: receiveProducerInfo,
    [Types.REQUEST_SAVE_REGISTRATIONS]: requestSaveRegistrations,
    [Types.RECEIVE_SAVE_REGISTRATIONS]: receiveSaveRegistrations,
    [Types.REQUEST_LIVESTOCK]: requestLivestock,
    [Types.RECEIVE_LIVESTOCK]: receiveLivestock,
    [Types.REQUEST_MORE_LIVESTOCK]: requestMoreLivestock,
    [Types.RECEIVE_MORE_LIVESTOCK]: receiveMoreLivestock,
    [Types.REQUEST_EXPANDED_REGISTRATION]: requestExpandedRegistration,
    [Types.RECEIVE_EXPANDED_REGISTRATION]: receiveExpandedRegistration,
    [Types.REQUEST_ENROLLMENT_CONFIGURATION]: requestEnrollmentConfiguration,
    [Types.RECEIVE_ENROLLMENT_CONFIGURATION]: receiveEnrollmentConfiguration,
};

export default createReducer(INITIAL_STATE, HANDLERS);
