import * as signalR from '@microsoft/signalr';

export default class SignalRConnection {
    connection = null;
    subscriptions = {};
    isConnected = false;
    isConnecting = false;

    constructor() {
        try {
            // Using try catch to avoid crashing the application
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl('hubs/SignalRHub')
                .withAutomaticReconnect()
                .configureLogging(signalR.LogLevel.Critical /*signalR.LogLevel.Information*/)
                .build();

            this.connection.onclose(error => {
                if (error) {
                    console.error(`SignalR: ${error}`);
                }
                this.isConnected = false;
            });

            this.connection.onreconnected(() => {
                this.subscribeToAll();
                this.isConnected = true;
            });

            this.connection.on('ping', this.handlePing.bind(this));
        } catch (ex) {
            console.error('SignalRConnection threw error in constructor ' + ex);
        }
    }

    addSubscription(name, messageName, onReceived = () => {}, param) {
        try {
            this.subscriptions[name] = {
                name,
                param: param || '',
                messageName,
                onReceived,
            };
            if (this.isConnected) {
                this.connection.invoke('Subscribe', name, param || '');
                this.connection.on(messageName, onReceived);
            } else {
                this.start();
                //Start will invoke, and add the onreceived fro all subscriptions
            }
        } catch (ex) {
            console.error('SignalRConnection threw error on addSubscription:' + ex);
        }
    }

    setOnReceived(name, onReceived) {
        try {
            if (this.subscriptions[name]) {
                this.subscriptions[name] = {
                    ...this.subscriptions[name],
                    onReceived,
                };
            } else {
                console.error('SignalR: Tried setting onreceived for a subscription that does not exist');
            }
        } catch (ex) {
            console.error('SignalRConnection threw error when trying to setOnReceived' + ex);
        }
    }

    handlePing(msg) {
        try {
            if (this.isConnected) {
                this.connection.invoke('Ping', msg);
            } else {
                console.error('SignalR: Received ping but is disconnected so cant respond');
            }
        } catch (ex) {
            console.error('SignalRConnection threw error when trying to handle ping:' + ex);
        }
    }

    async start() {
        try {
            if (!this.isConnected && !this.isConnecting) {
                this.isConnecting = true;
                await this.connection.start();
                this.isConnected = true;
                this.isConnecting = false;
                this.subscribeToAll();
            }
        } catch (ex) {
            console.error('SignalRConnection threw error when trying to start up:' + ex);
        }
    }

    subscribeToAll() {
        try {
            for (const key of Object.keys(this.subscriptions)) {
                const { name, param, messageName, onReceived } = this.subscriptions[key];
                this.connection.invoke('Subscribe', name, param);
                this.connection.on(messageName, onReceived);
            }
        } catch (ex) {
            console.error('SignalRConnection threw error when trying to subscribeToAll:' + ex);
        }
    }

    async removeSubscription(name, param, messageName) {
        try {
            delete this.subscriptions[name];
            await this.connection.invoke('Unsubscribe', name, param);
            this.connection.off(messageName);
            if (Object.keys(this.subscriptions).length === 0) {
                this.close();
            }
        } catch (ex) {
            console.error('SignalRConnection threw error when trying to remove subscription' + ex);
        }
    }

    async close() {
        try {
            await this.connection.stop();
            this.isConnected = false;
        } catch (ex) {
            console.error('SignalRConnection threw error when trying to close the connection:' + ex);
        }
    }
}
