import { connect } from 'react-redux';
import LoaderComponent from './LoaderComponent';

const mapStateToProps = (state, props) => {
    const { isLoading, phrases } = state.app;
    return { isLoading, phrases, ...props };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoaderComponent);
