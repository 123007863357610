import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    appHandleMessage: ['messageText', 'messageType', 'messageTitle'],
    requestRegistrations: [],
    receiveRegistrations: ['registrations'],
    requestMoreRegistrations: [],
    receiveMoreRegistrations: ['moreRegistrations'],
    addRegistration: ['registration'],
    updateRegistrations: ['registrations'],
    removeRegistration: ['index'],
    requestProducerInfo: [],
    receiveProducerInfo: ['producerInfo'],
    requestSaveRegistrations: ['registrations'],
    receiveSaveRegistrations: ['savedRegistrations'],
    requestLivestock: {},
    receiveLivestock: ['livestock'],
    requestMoreLivestock: {},
    receiveMoreLivestock: ['moreLivestock'],
    requestExpandedRegistration: [],
    receiveExpandedRegistration: ['expandedRegistrationDetails'],
    requestEnrollmentConfiguration: [],
    receiveEnrollmentConfiguration: ['enrollmentConfiguration'],
});

export { Creators, Types };
