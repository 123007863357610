import React from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { PhraseInput, PhraseButton, ModulePhrase, PhraseHeader, PhraseParagraph } from '../../components';
import { Responsive, Form, Image, Modal, Input, Button, Accordion, Divider } from 'semantic-ui-react';
import './general.css';

class RegistrationTransportationType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSelectedValue = value => {
        const { setValue, valueSet } = this.props;
        setValue(value);
        valueSet();
    };

    render = () => {
        const { value } = this.props;
        return (
            <React.Fragment>
                <ModulePhrase
                    defaultText="Ønskes henting eller egen transport til slakteriet?"
                    guid="35a3e05c-7b5f-43ec-a99c-709e4cda542c"
                    hasText
                >
                    <PhraseHeader as="h4" />
                </ModulePhrase>
                <Button.Group style={{ display: 'flex' }}>
                    <ModulePhrase
                        defaultText="Hentes"
                        defaultplaceholder="Fatland transport"
                        guid="8598ee6b-bad8-45cb-acfd-08c3608a61a2"
                        hasText
                        hasPlaceholder
                    >
                        <PhraseButton
                            onClick={() => this.handleSelectedValue('Hentes')}
                            active={value === 'Hentes'}
                            divStyle={{ flex: 1 }}
                            className="mitt-fatland-or-button-choice"
                            fluid
                        />
                    </ModulePhrase>
                    <Button.Or className="mitt-fatland-or-button" text="eller" />
                    <ModulePhrase
                        defaultText="Egen transport"
                        defaultplaceholder="Egen transport"
                        guid="5290e9ff-77cb-43af-86c3-308f43e008d2"
                        hasText
                        hasPlaceholder
                    >
                        <PhraseButton
                            onClick={() => this.handleSelectedValue('Leveres')}
                            active={value === 'Leveres'}
                            divStyle={{ flex: 1 }}
                            className="mitt-fatland-or-button-choice"
                            fluid
                        />
                    </ModulePhrase>
                </Button.Group>
            </React.Fragment>
        );
    };
}
export default RegistrationTransportationType;
