// @app/common/sidebar/duck/reducers.js
import { createReducer } from 'reduxsauce';
import { Types } from './actions';

export const INITIAL_STATE = {
    notifications: [],
    isNotificationsVisible: false,
    isLoadingNotifications: false,
};

export const showNotifications = (state = INITIAL_STATE, action) => {
    return { ...state, isNotificationsVisible: true };
};

export const hideNotifications = (state = INITIAL_STATE, action) => {
    return { ...state, isNotificationsVisible: false };
};

export const requestNotifications = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingNotifications: true };
};

export const receiveNotifications = (state = INITIAL_STATE, action) => {
    const { notifications } = action;
    return {
        ...state,
        notifications: notifications,
        isLoadingNotifications: false,
    };
};

export const markNotificationAsRead = (state = INITIAL_STATE, action) => {
    const { id } = action;
    return {
        ...state,
        notifications: state.notifications.map(notification => {
            if (notification.id !== id) {
                return notification;
            }
            return {
                ...notification,
                status: 'Read',
            };
        }),
    };
};

export const markNotificationAsDismissed = (state = INITIAL_STATE, action) => {
    const { id } = action;
    return {
        ...state,
        notifications: state.notifications.filter(notification => notification.id !== id),
    };
};

export const receiveNotification = (state = INITIAL_STATE, action) => {
    const { notification } = action;
    const { notifications } = state;
    return {
        ...state,
        notifications: [notification, ...notifications],
    };
};

export const readNotifications = (state = INITIAL_STATE, action) => {
    const { notifications } = state;
    return {
        ...state,
        notifications: notifications.map(item => {
            item.isRead = true;
            return item;
        }),
    };
};

export const HANDLERS = {
    [Types.NOTIFICATIONS_SHOW_NOTIFICATIONS]: showNotifications,
    [Types.NOTIFICATIONS_HIDE_NOTIFICATIONS]: hideNotifications,
    [Types.NOTIFICATIONS_REQUEST_NOTIFICATIONS]: requestNotifications,
    [Types.NOTIFICATIONS_RECEIVE_NOTIFICATIONS]: receiveNotifications,
    [Types.NOTIFICATIONS_RECEIVE_NOTIFICATION]: receiveNotification,
    [Types.NOTIFICATIONS_MARK_NOTIFICATION_AS_READ]: markNotificationAsRead,
    [Types.NOTIFICATIONS_MARK_NOTIFICATION_AS_DISMISSED]: markNotificationAsDismissed,
};

export default createReducer(INITIAL_STATE, HANDLERS);
