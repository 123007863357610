import { Creators } from './actions';
import { GetFiles } from './api';

const requestFilesAction = Creators.requestFiles;
const receiveFilesAction = Creators.receiveFiles;

const fetchFiles = orgGuid => {
    return dispatch => {
        dispatch(requestFilesAction());
        GetFiles(dispatch, orgGuid).then(result => {
            if (result) {
                dispatch(receiveFilesAction(result));
            }
        });
    };
};

export default {
    fetchFiles,
};
