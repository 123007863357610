import React from 'react';
import { Form, Checkbox, Popup, Icon, Loader } from 'semantic-ui-react';
import '../phrase.css';

class PhraseCheckboxComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            originalChecked: false,
            loading: false,
            isError: false,
        };
    }

    componentDidMount() {
        const { checked } = this.props;
        this.setState({ checked: checked, originalChecked: checked });
    }

    componentDidUpdate(prevProps) {
        const { checked } = this.props;
        if (checked !== prevProps.checked) {
            this.setState({ checked: checked });
        }
    }

    handleOnChange = e => {
        const { onSave } = this.props;
        const { name, checked } = e.target;
        this.setState({ checked: e.target.checked });

        if (!onSave) return;
        this.setState({ loading: true });

        onSave(name, checked)
            .then(result => {
                this.setState({ loading: false });
                if (!result || result.isError) {
                    this.setState({
                        isError: true,
                    });
                } else {
                    this.setState({
                        isError: false,
                    });
                }
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            });
    };

    render() {
        const { isError } = this.state;
        const { hasDescription, required, maxWidth } = this.props;
        if (hasDescription) {
            return (
                <Form.Field style={{ maxWidth: maxWidth }} required={required} error={isError}>
                    {this.renderWithDescription()}
                </Form.Field>
            );
        }
        return (
            <Form.Field style={{ maxWidth: maxWidth }} required={required}>
                {this.renderWithoutDescription()}
            </Form.Field>
        );
    }

    renderWithoutDescription = () => {
        return <React.Fragment>{this.renderCheckbox()}</React.Fragment>;
    };

    renderWithDescription = () => {
        const { description } = this.props;
        return (
            <React.Fragment>
                <Popup trigger={this.renderCheckbox()} content={description} />
            </React.Fragment>
        );
    };

    renderCheckbox = () => {
        const { checked, loading } = this.state;
        const {
            name,
            radio,
            fitted,
            indeterminate,
            onChange,
            readOnly,
            slider,
            tabIndex,
            toggle,
            type,
            disabled,
            hasLabel,
            className,
            value,
        } = this.props;
        return (
            <Checkbox
                name={name}
                checked={checked}
                radio={radio}
                fitted={fitted}
                indeterminate={indeterminate}
                readOnly={readOnly}
                slider={slider}
                tabIndex={tabIndex}
                toggle={toggle}
                type={type}
                onChange={onChange || this.handleOnChange}
                label={hasLabel && this.renderLabel()}
                disabled={disabled || loading}
                value={value}
                className={className ? `phrase-checkbox ${className}` : 'phrase-checkbox'}
            />
        );
    };

    renderLabel = () => {
        const { loading } = this.state;
        const { label, showEdit } = this.props;
        return (
            <label>
                {label} {loading && this.renderLoader()} {showEdit && this.renderEditIcon()}
            </label>
        );
    };

    renderEditIcon = () => {
        return <Icon name="edit" className="phrase-edit-icon" onClick={this.props.handleOpenEdit} />;
    };

    renderLoader = () => {
        return <Loader active inline size="mini" className="phrase-label-loader" />;
    };
}

export default PhraseCheckboxComponent;
