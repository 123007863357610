import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ModuleWrapperComponent from './ModuleWrapperComponent';
import { appOperations } from '../../../duck';
import { navBarOperations } from '../NavBar/duck';
import { sidebarOperations } from '../Sidebar/duck';
import { notificationsOperations } from '../Notifications/duck';

const mapStateToProps = (state, props) => {
    const {
        version,
        isLoading,
        config,
        modules,
        selectedModule,
        selectedSubModule,
        defaultModuleName,
        hasLoadedAppContext,
        hasLoadedAppContextSimplified,
        isLoadingModulePhrases,
        portalPhrases,
        hasLoadedPortalPhrases,
        selectedLang,
        messages,
        menu,
        showReturnButton,
        perms,
    } = state.app;
    const { authIdent } = state.auth;
    const { notifications, isLoadingNotifications, isNotificationsVisible } = state.notifications;
    const { isSidebarVisible } = state.sidebar;
    return {
        version,
        isLoading,
        hasLoadedAppContext,
        hasLoadedAppContextSimplified,
        config,
        modules,
        selectedModule,
        selectedSubModule,
        defaultModuleName,
        isLoadingModulePhrases,
        portalPhrases,
        hasLoadedPortalPhrases,
        selectedLang,
        messages,
        menu,
        isSidebarVisible,
        showReturnButton,
        notifications,
        isLoadingNotifications,
        isNotificationsVisible,
        authIdent,
        perms,
        ...props,
    };
};

const mapDispatchToProps = dispatch => {
    const setSelectedModule = module => {
        dispatch(appOperations.setSelectedModule(module));
    };
    const setSelectedSubModule = subModule => {
        dispatch(appOperations.setSelectedSubModule(subModule));
    };
    const removeMessage = guid => {
        dispatch(appOperations.removeMessage(guid));
    };
    const fetchModulePhrases = (moduleIdent, lang) => {
        dispatch(appOperations.fetchModulePhrases(moduleIdent, lang));
    };
    const handleShowSidebar = () => {
        dispatch(sidebarOperations.handleShowSidebar());
    };
    const handleHideSidebar = () => {
        dispatch(sidebarOperations.handleHideSidebar());
    };
    const setNavigationPath = path => {
        dispatch(appOperations.setNavigationPath(path));
    };
    const setShowReturnButton = show => {
        dispatch(appOperations.setShowReturnButton(show));
    };
    const updateReadNotifications = readNotifications => {
        dispatch(navBarOperations.updateReadNotifications(readNotifications));
    };
    const showNotifications = () => {
        dispatch(notificationsOperations.showNotifications());
    };

    return {
        setSelectedModule,
        setSelectedSubModule,
        removeMessage,
        fetchModulePhrases,
        handleShowSidebar,
        handleHideSidebar,
        setNavigationPath,
        setShowReturnButton,
        updateReadNotifications,
        showNotifications,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModuleWrapperComponent));
