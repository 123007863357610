import React from 'react';
import { Icon, Header, List } from 'semantic-ui-react';
import '../phrase.css';

class PhraseListItemComponent extends React.Component {
    render() {
        const {
            active,
            as,
            children,
            className,
            //content,
            description,
            disabled,
            header,
            icon,
            image,
            onClick,
            value,
            maxWidth,
            showEdit,
            text,
        } = this.props;
        return (
            <React.Fragment>
                {showEdit && this.renderEditIcon()}
                <List.Item
                    active={active}
                    as={as}
                    children={children}
                    className={className ? `phrase-list-item ${className}` : 'phrase-list-item'}
                    content={text}
                    description={description}
                    disabled={disabled}
                    header={header}
                    icon={icon}
                    image={image}
                    onClick={onClick}
                    value={value}
                    maxWidth={maxWidth}
                >
                    {children && this.renderChildren()}
                </List.Item>
            </React.Fragment>
        );
    }

    renderChildren = () => {
        const { children } = this.props;
        return children;
    };

    renderIcon = name => {
        return <Icon name={name} />;
    };

    renderDescription = () => {
        const { description } = this.props;
        return <Header.Subheader>{description}</Header.Subheader>;
    };

    renderLabel = () => {
        const { label, showEdit } = this.props;
        return (
            <label>
                {label} {showEdit && this.renderEditIcon()}
            </label>
        );
    };

    renderEditIcon = () => {
        return <Icon name="edit" className="phrase-edit-icon right" onClick={this.props.handleOpenEdit} />;
    };
}

export default PhraseListItemComponent;
