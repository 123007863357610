import { createReducer } from 'reduxsauce';
import { Types } from './actions';

export const INITIAL_STATE = {
    files: { isError: false, value: [] },
    isLoadingFiles: false,
};

export const requestFiles = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoadingFiles: true,
    };
};

export const receiveFiles = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return {
        ...state,
        files: response,
        isLoadingFiles: false,
    };
};

export const HANDLERS = {
    [Types.REQUEST_FILES]: requestFiles,
    [Types.RECEIVE_FILES]: receiveFiles,
};

export default createReducer(INITIAL_STATE, HANDLERS);
