import React from 'react';
import { Form, TextArea, Popup, Icon, Loader } from 'semantic-ui-react';
import '../phrase.css';

class PhraseTextAreaComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            originalValue: '',
            loading: false,
            isError: false,
        };
    }

    componentDidMount() {
        const { value } = this.props;
        this.setState({ value: value, originalValue: value });
    }

    handleOnChange = e => {
        const { isError } = this.state;
        const { required } = this.props;
        this.setState({ value: e.target.value });
        if (required && !e.target.value) {
            this.setState({ isError: true });
        } else if (isError) {
            this.setState({ isError: false });
        }
    };

    handleOnBlur = e => {
        const { required, onSave } = this.props;
        const { name, value } = e.target;
        if ((required && !this.state.value) || !onSave) return;
        if (this.state.value !== this.state.originalValue) {
            this.setState({ loading: true });

            onSave(name, value)
                .then(result => {
                    this.setState({ loading: false });
                    if (!result || result.isError) {
                        this.setState({
                            isError: true,
                        });
                    } else {
                        this.setState({
                            isError: false,
                        });
                    }
                })
                .catch(() => {
                    this.setState({
                        isLoading: false,
                        isError: true,
                    });
                });
        }
    };

    render() {
        const { isError } = this.state;
        const { hasDescription, required, maxWidth } = this.props;
        if (hasDescription) {
            return (
                <Form.Field style={{ maxWidth: maxWidth }} required={required} error={isError}>
                    {this.renderWithDescription()}
                </Form.Field>
            );
        }
        return (
            <Form.Field style={{ maxWidth: maxWidth }} required={required}>
                {this.renderWithoutDescription()}
            </Form.Field>
        );
    }

    renderWithoutDescription = () => {
        const { hasLabel } = this.props;
        return (
            <React.Fragment>
                {hasLabel && this.renderLabel()}
                {this.renderTextArea()}
            </React.Fragment>
        );
    };

    renderWithDescription = () => {
        const { description, hasLabel } = this.props;
        return (
            <React.Fragment>
                {hasLabel && this.renderLabel()}
                <Popup trigger={this.renderTextArea()} content={description} />
            </React.Fragment>
        );
    };

    renderTextArea = () => {
        const { loading } = this.state;
        const { value, name, placeholder, autoHeight, onBlur, rows, disabled, onChange, className } = this.props;
        return (
            <TextArea
                name={name}
                value={value || this.state.value}
                placeholder={placeholder}
                autoHeight={autoHeight}
                rows={rows}
                onChange={onChange || this.handleOnChange}
                onBlur={onBlur || this.handleOnBlur}
                disabled={disabled || loading}
                className={className ? `phrase-text-area ${className}` : 'phrase-text-area'}
            />
        );
    };

    renderLabel = () => {
        const { loading } = this.state;
        const { label, showEdit } = this.props;
        return (
            <label>
                {label} {loading && this.renderLoader()} {showEdit && this.renderEditIcon()}
            </label>
        );
    };

    renderEditIcon = () => {
        return <Icon name="edit" className="phrase-edit-icon" onClick={this.props.handleOpenEdit} />;
    };

    renderLoader = () => {
        return <Loader active inline size="mini" className="phrase-label-loader" />;
    };
}

export default PhraseTextAreaComponent;
