// @app/components/Puljeplan/duck/reducers.js
import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import { AnyAction } from "redux";
import {PuljeplanConfigurationType, PuljeplanDefinitionCollectionType} from "../components/PuljePlanSettings";

export type PuljeplanState = {
    puljeplan: any | null;
    puljeplanConfiguration: PuljeplanConfigurationType | null;
    puljeplanDefinitions: PuljeplanDefinitionCollectionType | null;
    isLoadingPuljeplan: boolean,
    isLoadingPuljeplanConfiguration: boolean;
    isLoadingPuljeplanDefinitions: boolean;
    isSavingPuljeplanConfiguration: boolean;
}

export const INITIAL_STATE: PuljeplanState = {
    puljeplan: null,
    puljeplanConfiguration: null,
    puljeplanDefinitions: null,
    isLoadingPuljeplan: false,
    isLoadingPuljeplanConfiguration: false,
    isLoadingPuljeplanDefinitions: false,
    isSavingPuljeplanConfiguration: false,
};

export const requestPuljeplan = (state = INITIAL_STATE, action: AnyAction) => {
    return { ...state, isLoadingPuljeplan: true };
};

export const receivePuljeplan = (state = INITIAL_STATE, action: AnyAction) => {
    const { puljeplan } = action;
    return { ...state, puljeplan: puljeplan, isLoadingPuljeplan: false };
};

export const requestPuljeplanConfiguration = (state = INITIAL_STATE, action: AnyAction) => {
    return { ...state, isLoadingPuljeplanConfiguration: true };
};

export const receivePuljeplanConfiguration = (state = INITIAL_STATE, action: AnyAction) => {
    const { puljeplanConfiguration } = action;
    return { ...state, puljeplanConfiguration: puljeplanConfiguration, isLoadingPuljeplanConfiguration: false };
};

export const requestPuljeplanDefinitions = (state = INITIAL_STATE, action: AnyAction) => {
    return { ...state, isLoadingPuljeplanDefinitions: true };
};

export const receivePuljeplanDefinitions = (state = INITIAL_STATE, action: AnyAction) => {
    const { puljeplanDefinitions } = action;
    return { ...state, puljeplanDefinitions: puljeplanDefinitions, isLoadingPuljeplanDefinitions: false };
};

export const savePuljeplanConfiguration = (state = INITIAL_STATE, action: AnyAction) => {
    return { ...state, isSavingPuljeplanConfiguration: true };
}

export const doneSavingPuljeplanConfiguration = (state = INITIAL_STATE, action: AnyAction) => {
    return { ...state, isSavingPuljeplanConfiguration: false };
}

export const HANDLERS = {
    [Types.REQUEST_PULJEPLAN]: requestPuljeplan,
    [Types.RECEIVE_PULJEPLAN]: receivePuljeplan,
    [Types.REQUEST_PULJEPLAN_CONFIGURATION]: requestPuljeplanConfiguration,
    [Types.RECEIVE_PULJEPLAN_CONFIGURATION]: receivePuljeplanConfiguration,
    [Types.REQUEST_PULJEPLAN_DEFINITIONS]: requestPuljeplanDefinitions,
    [Types.RECEIVE_PULJEPLAN_DEFINITIONS]: receivePuljeplanDefinitions,
    [Types.SAVE_PULJEPLAN_CONFIGURATION]: savePuljeplanConfiguration,
    [Types.DONE_SAVING_PULJEPLAN_CONFIGURATION]: doneSavingPuljeplanConfiguration,
};

export default createReducer(INITIAL_STATE, HANDLERS);
