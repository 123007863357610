import React from 'react';
import _ from 'lodash';
import { Menu, Icon } from 'semantic-ui-react';
import { PortalPhrase, PhraseMenuItem, PhraseParagraph } from '../../../components/';

class MenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.isOpen(),
            active: this.isActive(),
        };
    }

    isActive() {
        const { history, item } = this.props;
        let isActive = false;

        if (item.value) {
            if ('/' + item.value === history.location.pathname) {
                isActive = true;
            }
        }
        if (item.children) {
            item.children.forEach(child => {
                if ('/' + child.value === history.location.pathname) {
                    isActive = true;
                }
            });
        }

        return isActive;
    }

    isOpen() {
        return this.isActive() || this.props.item.open;
    }

    isChildSelected(value) {
        return '/' + value === this.props.history.location.pathname;
    }

    componentDidUpdate(prevPros) {
        const { moduleName, item } = this.props;
        if (prevPros.moduleName !== moduleName) {
            this.setState({
                open: this.isOpen(),
                active: this.isActive(),
            });
        }
    }

    render() {
        const { item, perms } = this.props;

        // Please note! The mandatory perm is for menu only, direct URL still works and needs access checks
        // Check for mandatory perm on main menu item
        if (item.mandatoryPerm) {
            if (!perms.includes(item.mandatoryPerm)) {
                return null;
            }
        }

        if (_.isEmpty(_.compact(item.children))) {
            return this.renderSingleItem();
        } else {
            // Check for mandatory perm on child menu items
            item.children = item.children.filter((i) => {
                return i.mandatoryPerm === undefined || perms.includes(i.mandatoryPerm)
            })
            return this.renderMultiItem();
        }
    }

    renderSingleItem = () => {
        const { item, moduleName } = this.props;
        return (
            <PortalPhrase defaultText={item.name} guid={item.phraseGuid} hasText={true}>
                <PhraseMenuItem
                    icon={
                        <div className="sidebar-menu-item-icon">
                            <Icon
                                name={item.icon}
                                className="left sidebar-menu-item-icon"
                                size="large"
                                style={{ fontSize: '1.7rem' }}
                            />
                        </div>
                    }
                    active={_.toLower(moduleName) === _.toLower(item.value)}
                    onClick={() => {
                        this.props.handleItemClick(item);
                    }}
                    className="sidebar-menu-item"
                />
            </PortalPhrase>
        );
    };

    renderMultiItem = () => {
        const { item, isSidebarVisible, handleItemClick } = this.props;
        const { open, active } = this.state;
        return (
            <Menu.Item className={`sidebar-menu-multi-item ${active && 'open'}`} as="button">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                        onClick={() =>
                            item.value
                                ? this.props.handleItemClick(item)
                                : open
                                ? active
                                    ? null
                                    : this.setState({ open: false })
                                : this.setState({ open: true })
                        }
                    >
                        <div className="sidebar-menu-item-icon">
                            <Icon
                                name={item.icon}
                                size="large"
                                style={{
                                    minWidth: '1rem',
                                    paddingRight: '0.2rem',
                                    fontSize: '1.7rem',
                                }}
                            />
                        </div>
                        <PortalPhrase defaultText={item.name} guid={item.phraseGuid} hasText={isSidebarVisible}>
                            <PhraseParagraph style={{ display: 'inline' }} />
                        </PortalPhrase>
                    </div>
                    {isSidebarVisible && (
                        <Icon
                            name={open ? 'chevron down' : 'chevron up'}
                            className="sidebar-menu-item-chevron"
                            onClick={() =>
                                active && open ? this.setState({ open: false }) : this.setState({ open: true })
                            }
                        />
                    )}
                </div>
                {_.get(item, 'children') && open && (
                    <Menu.Menu>
                        {_.map(_.compact(item.children), child => (
                            <Menu.Item
                                key={child.value}
                                className={`sidebar-menu-subitem ${this.isChildSelected(child.value) && 'selected'}`}
                                onClick={() => handleItemClick(child)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                {child.icon && (
                                    <div className="sidebar-menu-subitem-icon">
                                        <Icon name={child.icon} />
                                    </div>
                                )}
                                <PortalPhrase defaultText={child.name} guid={child.phraseGuid} hasText={true}>
                                    <PhraseParagraph
                                        className={
                                            'sidebar-menu-subitem-paragraph' + (child.icon ? '' : ' subitem-noIcon')
                                        }
                                    />
                                </PortalPhrase>
                            </Menu.Item>
                        ))}
                    </Menu.Menu>
                )}
            </Menu.Item>
        );
    };
}

export default MenuItem;
