const menuObject = [
    {
        name: 'Dashboard',
        icon: 'home',
        value: 'Dashboard',
    },
    {
        name: 'Innmeldinger',
        icon: 'file alternate',
        value: 'Registrations',
    },
    {
        name: 'Slaktedata',
        icon: 'clipboard list',
        value: 'SlaughterData',
        open: true,
        children: [
            {
                name: 'Detaljer Slaktedata',
                icon: null,
                value: 'Export/SlaughterDataExport',
            },
        ],
    },
    {
        name: 'Avregning',
        icon: 'dollar',
        value: 'Calculations',
    },
    {
        name: 'Årsoppgave',
        icon: 'tasks',
        value: 'AnnualStatement',
    },
    {
        name: 'Puljeplan gris',
        icon: 'calendar alternate outline',
        value: 'Puljeplan',
    },
    {
        name: 'Slakteplan gris',
        icon: 'chart line',
        value: 'SlaughterPlan',
    },
    {
        name: 'Eksport',
        icon: 'file',
        children: [
            {
                name: 'LED SAU',
                icon: null,
                value: 'Export/LedSheep',
            },
        ],
    },
    {
        name: 'Min side',
        icon: 'user',
        value: 'MyPage',
    },
    {
        name: 'Adminstrasjon',
        icon: 'users',
        mandatoryPerm: 'users-read',
        children: [
            {
                name: 'Produsenter',
                icon: null,
                value: 'Administration/Users',
            },
            {
                name: 'Konfigurasjon',
                icon: null,
                mandatoryPerm: 'enrollmentconfiguration-update',
                value: 'Administration/Configuration',
            },
            {
                name: 'Slakteplaner',
                icon: null,
                value: 'Administration/SlaughterPlanConfiguration',
            }
        ],
    },
];

export default menuObject;
