import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { default as appReducer } from './duck/reducers';
import { default as authReducer } from './app/common/Auth/duck';
import { default as moduleReducer } from './app/common/ModuleWrapper/duck';
import { default as sidebarReducer } from './app/common/Sidebar/duck/reducers';
import { default as notificationsReducer } from './app/common/Notifications/duck/reducers';
import { default as navBarReducer } from './app/common/NavBar/duck/reducers';
import { default as privacyReducer } from './app/common/Privacy/duck';
import { default as myPageReducer } from './app/modules/MyPage/duck/reducers';
import { default as puljeplanReducer } from './app/modules/Puljeplan/duck/reducers';
import { default as slaughterPlanReducer } from './app/modules/SlaughterPlan/duck/reducers';
import { default as fatlandRegistrationsReducer } from './app/modules/Registrations/duck/reducers';
import { default as slaughterDataReducer } from './app/modules/SlaughterData/duck/reducers';
import { default as dashboardReducer } from './app/modules/Dashboard/duck/reducers';
import { default as calculationsReducer } from './app/modules/Calculations/duck';

const rootReducer = history => {
    let tempWasNotFound;

    const reducers = combineReducers({
        router: connectRouter(history),
        app: appReducer,
        auth: authReducer,
        module: moduleReducer,
        sidebar: sidebarReducer,
        navBar: navBarReducer,
        notifications: notificationsReducer,
        privacy: privacyReducer,
        myPage: myPageReducer,
        puljeplan: puljeplanReducer,
        slaughterPlans: slaughterPlanReducer,
        fatlandRegistrations: fatlandRegistrationsReducer,
        slaughterData: slaughterDataReducer,
        dashboard: dashboardReducer,
        calculations: calculationsReducer,
    });

    return (state, action) => {
        if (action.type === 'LOGOUT_SUCCESS' || action.type === 'LOGOUT_FAILED') {
            tempWasNotFound = state.auth.wasNotFound;
            state = undefined;
            state = {
                auth: {
                    wasNotFound: tempWasNotFound
                }
            }
        }
        return reducers(state, action);
    };
};

export default rootReducer;
