// duck/reducers.js
import { createReducer } from 'reduxsauce';
import _ from 'lodash';
import { Types } from './actions';

export const INITIAL_STATE = {
    privacyPolicy: null,
    isLoadingPrivacyPolicy: false,
};

export const requestPrivacyPolicy = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingPrivacyPolicy: true,
    };
};

export const receivePrivacyPolicy = (state = INITIAL_STATE, action) => {
    const { policy } = action;
    return {
        ...state,
        privacyPolicy: policy,
        isLoadingPrivacyPolicy: false,
    };
};

export const HANDLERS = {
    [Types.PRIVACY_REQUEST_PRIVACY_POLICY]: requestPrivacyPolicy,
    [Types.PRIVACY_RECEIVE_PRIVACY_POLICY]: receivePrivacyPolicy,
};

export default createReducer(INITIAL_STATE, HANDLERS);
