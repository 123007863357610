import React from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Segment, Header, Icon, Message } from 'semantic-ui-react';
import { GlobalPhrase, PhraseButton, PhraseHeader } from '../../components';

class ErrorComponent extends React.Component {
    handleHomeButtonOnClick = () => {
        this.props.history.push('/');
    };

    handleLogOutButtonOnClick = () => {
        this.props.history.push('/signout');
    };

    render() {
        const search = new URLSearchParams(this.props.location.search);
        const errorMessage = search.get('errorMessage');
        return (
            <Segment placeholder>
                <Header icon>
                    <Icon name="frown outline" />
                    <GlobalPhrase
                        hasText
                        defaultText="Noe gikk fryktelig galt!"
                        guid="a09663ab-a76c-4d8a-8251-b0825c794c5e"
                    >
                        <PhraseHeader />
                    </GlobalPhrase>
                </Header>
                {errorMessage && this.renderErrorMessage(errorMessage)}
                <Segment.Inline
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <GlobalPhrase hasText defaultText="Tilbake" guid="87c5592b-790b-4a8f-b84b-15ec2cd66d78">
                        <PhraseButton onClick={this.handleHomeButtonOnClick} icon primary style={{ margin: '0.5em' }} />
                    </GlobalPhrase>
                    {/* <GlobalPhrase
                        hasText
                        defaultText="Logg ut"
                        guid="2caa20da-e538-4bce-8eae-f93ac478b34f"
                    >
                        <PhraseButton
                            onClick={this.handleLogOutButtonOnClick}
                            icon
                            primary
                            style={{ margin: '0.5em' }}
                        />
                    </GlobalPhrase> */}
                </Segment.Inline>
            </Segment>
        );
    }

    renderErrorMessage = errorMessage => {
        return (
            <Message negative>
                <Message.Header>Response from server</Message.Header>
                <p>{errorMessage}</p>
            </Message>
        );
    };
}

export default withRouter(ErrorComponent);
