import React from 'react';
import _ from 'lodash';
import { Menu, Responsive } from 'semantic-ui-react';
import { ModuleMenuItem } from './';

class ModuleMenu extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Responsive minWidth={Responsive.onlyTablet.maxWidth} className="module-menu-container" fireOnMount>
                    {this.renderModuleMenuDesktop()}
                </Responsive>
                <Responsive maxWidth={Responsive.onlyTablet.maxWidth} className="module-menu-container" fireOnMount>
                    {this.renderModuleMenuMobile()}
                </Responsive>
            </React.Fragment>
        );
    }

    renderModuleMenuDesktop = () => {
        return (
            <Menu icon="labeled" className="module-menu" vertical borderless>
                {this.renderMenuItemList()}
            </Menu>
        );
    };

    renderModuleMenuMobile = () => {
        return (
            <Menu icon="labeled" className="module-menu" vertical borderless>
                {this.renderMenuItemList()}
            </Menu>
        );
    };

    renderMenuItemList = () => {
        const { moduleName, menu, subModuleName } = this.props;
        return _.map(menu, (item, i) => (
            <ModuleMenuItem
                key={`${moduleName}_${item.name}`}
                item={item}
                subModuleName={subModuleName}
                handleItemClick={this.props.handleMenuItemClick}
            />
        ));
    };
}

export default ModuleMenu;
