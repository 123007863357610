import { connect } from 'react-redux';
import NotificationsComponent from './NotificationsComponent';
import { notificationsOperations } from './duck';
import { withRouter } from 'react-router';

const mapStateToProps = state => {
    const { notifications, isNotificationsVisible, isLoadingNotifications } = state.notifications;
    return { notifications, isNotificationsVisible, isLoadingNotifications };
};

const mapDispatchToProps = dispatch => {
    const showNotifications = () => {
        dispatch(notificationsOperations.showNotifications());
    };
    const hideNotifications = () => {
        dispatch(notificationsOperations.hideNotifications());
    };
    const fetchAllNotifications = (langId, skip, take) => {
        dispatch(notificationsOperations.fetchAllNotifications(langId, skip, take));
    };
    const markNotificationAsRead = id => {
        dispatch(notificationsOperations.markAsRead(id));
    };
    const markNotificationAsDismissed = id => {
        dispatch(notificationsOperations.markAsDismissed(id));
    };
    const addNotification = notification => {
        dispatch(notificationsOperations.receiveNotification(notification));
    };
    return {
        showNotifications,
        hideNotifications,
        fetchAllNotifications,
        markNotificationAsRead,
        markNotificationAsDismissed,
        addNotification,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationsComponent));
