import React from 'react';
import _ from 'lodash';
import {
    Menu,
    Image,
    Responsive,
    Icon,
    Input,
    Label,
    Popup,
    Loader,
    Accordion,
    Dropdown,
    Flag,
    Breadcrumb,
} from 'semantic-ui-react';
import { GlobalPhrase, PortalPhrase, PhraseSpan, PhraseMenuItem, PhraseBreadcrumb } from '../../components';
import { DeviceTypes } from '../../utility/deviceTypes';
import './navbar.css';
import { ImpersonatedUserContext } from '../../Contexts/ImpersonatedUserContext';

class NavBarComponent extends React.Component {
    static contextType = ImpersonatedUserContext;
    _isMounted = false;
    _myPageModuleName = 'MyPage';
    _languageToflag = [
        { flag: 'no', value: 'nb', text: 'Norwegian' },
        { flag: 'gb', value: 'en', text: 'English' },
    ];

    constructor(props) {
        super(props);
        this.state = {
            name: null,
            ident: null,
            selectedLang: null,
            dropUpOpen: false,
        };
    }

    componentDidMount = () => {
        this._isMounted = true;
        const { selectedLang, authIdent } = this.props;

        this.setState({
            selectedLang: selectedLang,
            name: _.get(authIdent, 'name'),
            ident: _.get(authIdent, 'ident'),
        });

        if (!this._isMounted) {
            return;
        }
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    componentDidUpdate = prevProps => {
        const { selectedLang } = this.props;
        if (selectedLang !== prevProps.selectedLang) {
            this.setState({ selectedLang: selectedLang });
        }
    };

    handleToggleSidebar = () => {
        if (this.props.isSidebarVisible) {
            this.props.handleHideSidebar();
        } else {
            this.props.handleShowSidebar();
        }
    };

    handleAvatarOnClick = () => {
        const { modules } = this.props;
        const { moduleName } = this.props.match.params;
        if (_.toLower(moduleName) !== _.toLower(this._myPageModuleName)) {
            const module = _.find(modules, m => {
                return _.toLower(m.name) === _.toLower(this._myPageModuleName);
            });
            if (!_.isEmpty(_.get(module, 'menu'))) {
                const defaultSubModuleName = _.get(module, 'config.defaultSubModule') || _.get(module, 'menu[0].value');
                this.props.history.push(`/${module.name}/${defaultSubModuleName}`);
            } else {
                this.props.history.push(`/${module.name}`);
            }
        }
    };

    handleSignOutOnClick = () => {
        this.props.history.push('/signout');
    };

    handleLanguageOnChange = (e, data) => {
        const { setSelectedLang } = this.props;
        this.setState({ selectedLang: data.value });
        setSelectedLang(data.value);
    };

    handleMenuItemClick = newModuleName => {
        const { modules, deviceType } = this.props;
        const { moduleName, subModuleName, foo } = this.props.match.params;
        const module = _.find(modules, m => {
            return _.toLower(m.name) === _.toLower(newModuleName);
        });
        const defaultSubModuleName = _.get(module, 'config.defaultSubModule') || _.get(module, 'menu[0].value');
        if (!_.isEmpty(_.get(module, 'menu'))) {
            if (
                _.toLower(newModuleName) !== _.toLower(moduleName) ||
                (subModuleName && !_.some(module.menu, m => _.toLower(m.name) === _.toLower(subModuleName)))
            ) {
                this.props.history.push(`/${newModuleName}/${defaultSubModuleName}`);
            }
        } else if (
            _.toLower(newModuleName) !== _.toLower(moduleName) ||
            (subModuleName && subModuleName !== defaultSubModuleName)
        ) {
            this.props.history.push(`/${newModuleName}`);
        } else if (foo) {
            if (defaultSubModuleName) {
                this.props.history.push(`/${newModuleName}/${defaultSubModuleName}`);
            } else {
                this.props.history.push(`/${newModuleName}`);
            }
        }
        if (deviceType === DeviceTypes.mobile || deviceType === DeviceTypes.tablet) {
            this.props.handleHideSidebar();
        }
    };

    render() {
        return (
            <React.Fragment>
                <Responsive
                    minWidth={Responsive.onlyTablet.maxWidth}
                    className="navbar-container navbar-height"
                    fireOnMount
                >
                    {this.renderNavBarDesktop()}
                </Responsive>
                <Responsive maxWidth={Responsive.onlyTablet.maxWidth} className="navbar-container" fireOnMount>
                    {this.renderMobileNavBar()}
                </Responsive>
            </React.Fragment>
        );
    }

    renderNavBarDesktop = () => {
        const { isSidebarVisible, config, perms, authIdent } = this.props;
        const { selectedLang } = this.state;
        const supportedLangs = _.get(config, 'supportedLangs');
        const impersonatedUserContext = this.context;
        return (
            <Menu className="navbar-menu" borderless>
                <Menu.Item
                    className="navbar-menu-item"
                    name="toggle"
                    active={isSidebarVisible}
                    onClick={this.handleToggleSidebar}
                >
                    <Icon name="bars" />
                </Menu.Item>
                {this.renderBreadcrumb()}
                <Menu.Menu position="right">
                    {supportedLangs && supportedLangs.length > 1 && (
                        <Dropdown
                            trigger={this.renderSelectedLanguage()}
                            options={_.filter(this._languageToflag, lang => {
                                return _.find(supportedLangs, v => v === lang.value);
                            })}
                            value={selectedLang}
                            onChange={this.handleLanguageOnChange}
                            item
                            icon={null}
                        />
                    )}
                    {perms.includes('fatland-users-impersonate') && impersonatedUserContext.impersonatedUser !== null && (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '0.2em',
                            }}
                        >
                            <p
                                style={{
                                    margin: '0',
                                    color: 'black',
                                    marginRight: '0.5em',
                                }}
                            >
                                Innlogget som:
                            </p>
                            <span style={{ color: 'red' }}>{impersonatedUserContext.impersonatedUser.name}</span>
                        </div>
                    )}
                    {this.renderAvatar()}
                </Menu.Menu>
            </Menu>
        );
    };

    renderBreadcrumb = () => {
        const { navigationPath } = this.props;
        return (
            <Menu.Item>
                <Breadcrumb>
                    {_.map(navigationPath, (element, i) => (
                        <React.Fragment key={i}>
                            <PortalPhrase
                                defaultText={_.get(element, 'defaultText', '')}
                                guid={_.get(element, 'guid', '')}
                                hasText={true}
                            >
                                <PhraseBreadcrumb
                                    link
                                    onClick={() => this.props.history.push(_.get(element, 'link', '/'))}
                                />
                            </PortalPhrase>
                            <Breadcrumb.Divider />
                        </React.Fragment>
                    ))}
                </Breadcrumb>
            </Menu.Item>
        );
    };

    renderMobileNavBar = () => {
        const { menu } = this.props;
        return <React.Fragment>{menu && this.renderDockMenu()} </React.Fragment>;
    };

    renderDockMenu = () => {
        const { selectedModule, menu } = this.props;
        const length = _.get(menu, 'length') > 4 ? 4 : _.get(menu, 'length');
        return (
            <Menu borderless fixed="bottom" className="navbar-dock">
                {_.map(_.take(menu, 3), (item, i) => (
                    <div key={i} style={{ width: 100 / length + '%' }}>
                        <PortalPhrase
                            defaultText={item.name}
                            guid={item.phraseGuid}
                            hasText={true}
                            className="dock-item"
                        >
                            <PhraseMenuItem
                                icon={<Icon name={item.icon} className="dock-item-icon" size="large" />}
                                active={_.toLower(_.get(selectedModule, 'name')) === _.toLower(item.value)}
                                onClick={() => this.handleMenuItemClick(item.value)}
                            />
                        </PortalPhrase>
                    </div>
                ))}
                {length === 4 && (
                    <div style={{ width: 25 + '%' }}>
                        <PortalPhrase
                            defaultText={'More'}
                            guid={'d4167d8b-8df6-48c1-9973-0c06b8a0e003'}
                            hasText={true}
                            className="dock-item"
                        >
                            <PhraseMenuItem
                                icon={<Icon name="sidebar" className="dock-item-icon" size="large" />}
                                active={_.some(
                                    _.drop(menu, 3),
                                    modul => _.toLower(_.get(selectedModule, 'name')) === _.toLower(modul.value)
                                )}
                                onClick={this.handleToggleSidebar}
                            />
                        </PortalPhrase>
                    </div>
                )}
            </Menu>
        );
    };

    renderLogo = () => {
        const { config } = this.props;
        return (
            <Menu.Item>
                <Image src={_.get(config, 'logo')} className="sidebar-logo" verticalAlign="middle" />
            </Menu.Item>
        );
    };

    renderSearch = () => {
        return (
            <Menu.Item className="navbar-menu-item">
                <Input icon="search" placeholder="Search..." />
            </Menu.Item>
        );
    };

    renderSelectedLanguage = () => {
        const { selectedLang } = this.state;
        const { config } = this.props;
        const supportedLangs = _.get(config, 'supportedLangs');
        const lang = _.find(supportedLangs, l => {
            return l === selectedLang;
        });
        if (!lang) return <Icon loading name="spinner" />;
        const flag = _.find(this._languageToflag, flag => flag.value === selectedLang);
        return (
            <React.Fragment>
                <Flag name={flag.flag} /> {flag.value}
            </React.Fragment>
        );
    };

    renderNotificationsIcon = () => {
        const { notifications, isLoadingNotifications } = this.props;

        const newCount = _.size(
            _.filter(notifications, n => {
                return n.status !== 'Read';
            })
        );

        return (
            <Menu.Item className="navbar-menu-item">
                {(isLoadingNotifications && <Loader active size="mini" />) || (
                    <Icon name="bell" size="large" style={{ marginLeft: '0.35em' }} />
                )}
                {!isLoadingNotifications && newCount > 0 && this.renderNewNotificationsCount(newCount)}
            </Menu.Item>
        );
    };

    renderNewNotificationsCount = newCount => {
        return (
            <Label color="red" circular floating size="mini" style={{ top: '0.40rem', left: '65%' }}>
                {newCount}
            </Label>
        );
    };

    renderNotification = (notification, key) => {
        const { activeNotification } = this.state;
        return (
            <React.Fragment key={key}>
                <Accordion.Title
                    active={activeNotification === key}
                    index={key}
                    onClick={this.handleNotificationOnClick}
                >
                    <Icon name="dropdown" />
                    {notification.subject || 'Notification'}
                    <Icon
                        link
                        name="close"
                        onClick={() => this.handleNotificationCloseOnClick(notification)}
                        style={{ float: 'right' }}
                    />
                </Accordion.Title>
                <Accordion.Content active={activeNotification === key}>
                    <p>{notification.text}</p>
                </Accordion.Content>
            </React.Fragment>
        );
    };

    renderAvatar = () => {
        const { modules } = this.props;
        const { moduleName } = this.props.match.params;
        const { name } = this.state;
        const myPageModule = _.find(modules, m => {
            return _.toLower(m.name) === _.toLower(this._myPageModuleName);
        });

        return (
            <Dropdown
                trigger={
                    <Image
                        src="https://mittfatlandstorage.blob.core.windows.net/public/default_avatar.png"
                        className="navbar-avatar"
                        avatar
                        style={{
                            margin: '0',
                            marginLeft: '0.25em',
                            objectFit: 'cover',
                        }}
                    />
                }
                icon={null}
                item
                direction="left"
                className="navbar-dropdown-menu"
            >
                <Dropdown.Menu>
                    <Dropdown.Header content={name} />
                    <Dropdown.Item
                        onClick={this.handleAvatarOnClick}
                        active={_.toLower(moduleName) === _.toLower(this._myPageModuleName)}
                    >
                        <PortalPhrase defaultText="MyPage" guid={_.get(myPageModule, 'titleGuid')} hasText>
                            <PhraseSpan icon="home" />
                        </PortalPhrase>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleSignOutOnClick}>
                        <GlobalPhrase defaultText="Sign out" guid="e7229dd5-5303-4b5c-b1fa-8b9f0cad4a74" hasText>
                            <PhraseSpan icon="log out" />
                        </GlobalPhrase>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    //renderSignOutConfirmationModal = () => {
    //    const { isSignOutConfirmationOpen } = this.state;
    //    return (<Modal open={isSignOutConfirmationOpen} onClose={this.handleSignOutConfirmationOnClose}>
    //        <Modal.Header>Sign out</Modal.Header>
    //        <Modal.Content>
    //            <p>Are you sure you want to siogn out?</p>
    //        </Modal.Content>
    //        <Modal.Actions>
    //            <Button negative>No</Button>
    //            <Button positive icon='checkmark' labelPosition='right' content='Yes' />
    //        </Modal.Actions>
    //    </Modal>);
    //}
}

export default NavBarComponent;
