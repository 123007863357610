import React, { createContext, useState, useReducer } from 'react';

let reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_GRID_REQUESTED':
            return {
                ...state,
                loadingGridData: true,
                fetchGridError: null,
            };
        case 'FETCH_GRID_SUCCEEDED':
            return {
                ...state,
                gridData: action.payload.items,
                totalTillegg: action.payload.totalTillegg,
                loadingGridData: false,
            };
        case 'FETCH_GRID_FAILED':
            return {
                ...state,
                loadingGridData: false,
                gridData: [],
                totalTillegg: '',
                fetchGridError: action.payload.items,
            };
        case 'FETCH_TOTAL_REQUESTED':
            return {
                ...state,
                loadingGridTotal: true,
                fetchGridTotalError: null,
            };
        case 'FETCH_TOTAL_SUCCEEDED':
            return {
                ...state,
                gridTotalData: action.payload,
                loadingGridTotal: false,
            };
        case 'FETCH_TOTAL_FAILED':
            return {
                ...state,
                loadingGridTotal: false,
                gridTotalData: [],
                totalTillegg: '',
                fetchGridTotalError: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

const initialState = {
    gridData: [],
    loadingGridData: true,
    gridTotalData: [],
    loadingGridTotal: true,
};

const ImpersonatedUserContext = createContext();

const ImpersonatedUserProvider = ({ children }) => {
    const [impersonatedUser, setImpersonatedUser] = useState(null);

    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        state,
        dispatch,
        impersonatedUser,
        setImpersonatedUser,
    };

    return <ImpersonatedUserContext.Provider value={value}>{children}</ImpersonatedUserContext.Provider>;
};

export { ImpersonatedUserProvider, ImpersonatedUserContext };
