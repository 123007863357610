import React from 'react';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';

class SuccessMessageComponent extends React.Component {
    render() {
        const { guid, message, header, onDismiss } = this.props;
        return <Message onDismiss={() => onDismiss(guid)} header={header} content={message} success />;
    }
}

export default SuccessMessageComponent;
