import React from 'react';
import _ from 'lodash';
import { Menu, Responsive } from 'semantic-ui-react';
import { SidebarMenuItem } from './';
import menuObject from '../MenuObject';

class SidebarMenu extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Responsive minWidth={Responsive.onlyTablet.maxWidth} className="sidebar-menu-container" fireOnMount>
                    {this.renderMenuDesktop()}
                </Responsive>
                <Responsive maxWidth={Responsive.onlyTablet.maxWidth} className="sidebar-menu-container" fireOnMount>
                    {this.renderMenuMobile()}
                </Responsive>
            </React.Fragment>
        );
    }

    renderMenuDesktop = () => {
        const { subModuleName, moduleName, handleItemClick, isSidebarVisible, perms, history } = this.props;
        return (
            <Menu className="sidebar-menu" icon="labeled" vertical borderless>
                {_.map(menuObject, (item, i) => (
                    <SidebarMenuItem
                        item={item}
                        key={i}
                        moduleName={moduleName}
                        isSidebarVisible={isSidebarVisible}
                        handleItemClick={handleItemClick}
                        subModuleName={subModuleName}
                        perms={perms}
                        history={history}
                    />
                ))}
            </Menu>
        );
    };

    renderMenuMobile = () => {
        const { subModuleName, handleItemClick, isSidebarVisible, perms, history } = this.props;
        return (
            <Menu className="sidebar-menu" vertical borderless>
                {_.map(menuObject, (menuItem, i) => {
                    return (
                        <SidebarMenuItem
                            item={menuItem}
                            key={i}
                            isSidebarVisible={isSidebarVisible}
                            handleItemClick={handleItemClick}
                            subModuleName={subModuleName}
                            perms={perms}
                            history={history}
                        />
                    );
                })}
            </Menu>
        );
    };
}

export default SidebarMenu;
