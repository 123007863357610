import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { Menu, Icon, Responsive, Dropdown, Loader, Button, Label } from 'semantic-ui-react';
import { PortalPhrase, PhraseHeader, PhraseParagraph, PhraseMenuItem } from '../../../components';
import { MobileNotification } from './';
import './components.css';
import { ImpersonatedUserContext } from '../../../Contexts/ImpersonatedUserContext';

class ModuleTitlebar extends React.Component {
    static contextType = ImpersonatedUserContext;
    handleReturnClick = () => {
        const { prevRoute, module } = this.props;
        const { moduleName } = this.props.match.params;
        if (prevRoute) {
            this.props.history.goBack();
        } else {
            this.props.history.push(
                `/${moduleName}/${
                    _.get(module, 'config.defaultSubModule') || _.get(_.head(module.menu), 'name') || ' '
                }`
            );
        }
    };

    render() {
        return (
            <React.Fragment>
                <Responsive minWidth={Responsive.onlyTablet.maxWidth} className="module-titlebar-container" fireOnMount>
                    {this.renderMobile()}
                </Responsive>
                <Responsive
                    maxWidth={Responsive.onlyTablet.maxWidth}
                    className="module-titlebar-container-mobile"
                    fireOnMount
                >
                    {this.renderMobile()}
                </Responsive>
            </React.Fragment>
        );
    }

    renderDesktop = () => {
        const { module, subModule, showReturnButton } = this.props;
        return (
            <Menu className="module-titlebar" borderless>
                {showReturnButton && this.renderReturnButton()}
                <Menu.Item className="module-titlebar-item">
                    <PortalPhrase defaultText={module.name} guid={module.titleGuid} hasText style={{ margin: 'auto' }}>
                        <PhraseHeader />
                    </PortalPhrase>
                    {subModule && this.renderSubModuleHeader()}
                </Menu.Item>
                <Menu.Menu position="right">
                    <Menu.Item>
                        <Icon name="ellipsis horizontal" />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        );
    };

    renderMobile = () => {
        const { module, subModule, showReturnButton, toggleSidebar, ident, perms } = this.props;
        const { subModuleName } = this.props.match.params;
        const phrase =
            _.get(module, 'config.defaultSubModule') === subModuleName
                ? module
                : _.isNull(subModule)
                ? module
                : subModule;
        const impersonatedUserContext = this.context;
        return (
            <div className="module-titlebar">
                <div style={{ flex: 0 }}>
                    <Menu borderless secondary>
                        {showReturnButton ? (
                            this.renderReturnButton()
                        ) : (
                            <Menu.Item name="openSidebar" onClick={toggleSidebar}>
                                <Icon name="sidebar" />
                            </Menu.Item>
                        )}
                    </Menu>
                </div>
                {perms.includes('fatland-users-impersonate') && impersonatedUserContext.impersonatedUser !== null && (
                    <span style={{ color: 'red', marginBottom: '0.1em' }}>
                        <Icon name="user" />
                        {impersonatedUserContext.impersonatedUser.name}
                    </span>
                )}
                <div style={{ flex: 1 }}>
                    <PortalPhrase
                        defaultText={_.get(phrase, 'name')}
                        guid={_.get(phrase, 'titleGuid')}
                        hasText
                        className="module-title-mobile nowrap"
                    >
                        <PhraseMenuItem />
                    </PortalPhrase>
                </div>
                <div style={{ flex: 0 }}>
                    <Menu borderless secondary>
                        {this.renderNotificationsIcon()}
                    </Menu>
                </div>
            </div>
        );
    };

    renderNotificationsIcon = () => {
        const { isLoadingNotifications, isNotificationsVisible, notifications, showNotifications, hideNotifications } =
            this.props;

        const newCount = _.size(
            _.filter(notifications, n => {
                return n.status !== 'Read';
            })
        );

        return (
            <Menu.Item>
                {(isLoadingNotifications && <Loader active size="mini" />) || (
                    <Button
                        style={{ background: 'none', padding: 0 }}
                        onClick={() => {
                            if (isNotificationsVisible) {
                                hideNotifications();
                            } else {
                                showNotifications();
                            }
                        }}
                    >
                        <Icon name="bell" size="large" />
                        {newCount > 0 && (
                            <Label color="red" size="tiny" floating circular>
                                {newCount}
                            </Label>
                        )}
                    </Button>
                )}
            </Menu.Item>
        );
    };

    renderReturnButton = () => {
        return (
            <Menu.Item name="Return" onClick={this.handleReturnClick}>
                <Icon name="chevron left" />
            </Menu.Item>
        );
    };

    renderSubModuleHeader = () => {
        const { subModule } = this.props;
        return (
            <React.Fragment>
                <span>&nbsp;|&nbsp;</span>
                <PortalPhrase
                    defaultText={subModule.name}
                    guid={subModule.titleGuid}
                    hasText
                    style={{ margin: 'auto' }}
                >
                    <PhraseHeader />
                </PortalPhrase>
            </React.Fragment>
        );
    };
}

export default withRouter(ModuleTitlebar);
