import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { appOperations } from '../../../../duck';
import PhraseComponent from './PhraseComponent';

class PortalPhraseComponent extends React.Component {
    render() {
        const {
            portalPhrases,
            globalPhrases,
            isPortalPhraseAdmin,
            isEditable,
            isPhraseEditMode,
            isSavingPortalPhrases,
        } = this.props;

        return (
            <PhraseComponent
                phrases={portalPhrases}
                globalPhrases={globalPhrases}
                showEdit={isPhraseEditMode && isEditable && isPortalPhraseAdmin}
                onSavePhrases={this.handleOnSavePhrases}
                isSaving={isSavingPortalPhrases}
                {...this.props}
            />
        );
    }

    handleOnSavePhrases = phrases => {
        const { selectedLang } = this.props;
        return this.props.savePortalPhrases(selectedLang, phrases);
    };
}

const mapStateToProps = (state, props) => {
    const isPortalPhraseAdmin = false;
    const { selectedLang, globalPhrases, portalPhrases, isPhraseEditMode, isSavingPortalPhrases } = state.app;
    return {
        selectedLang,
        globalPhrases,
        portalPhrases,
        isPortalPhraseAdmin,
        isPhraseEditMode,
        isSavingPortalPhrases,
    };
};

const mapDispatchToProps = dispatch => {
    const savePortalPhrases = (lang, phrases) => {
        return dispatch(appOperations.savePortalPhrases(lang, phrases));
    };
    return { savePortalPhrases };
};

PortalPhraseComponent.defaultProps = {
    isEditable: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortalPhraseComponent);
