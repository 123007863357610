import React from 'react';
import _ from 'lodash';
import { Form, Loader, Message } from 'semantic-ui-react';
import {
    GlobalPhrase,
    PortalPhrase,
    ModulePhrase,
    PhraseInput,
    PhraseDropdown,
    PhraseParagraph,
    PhraseCheckbox,
    PhraseHeader,
} from '../';

class FieldWrapperComponent extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            options: [],
            isLoading: false,
            isError: false,
            errorMessage: null,
        };
    }

    componentDidMount() {
        const { fieldType, value } = this.props;
        this._isMounted = true;
        if (_.toLower(fieldType) === 'dropdown') {
            this.getDataRefs();
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    getDataRefs = () => {
        const { dataType, fetchDataRefs, moduleName } = this.props;
        this.setState({ isLoading: true });
        fetchDataRefs(moduleName, dataType)
            .then(result => {
                if (this._isMounted) {
                    if (result && !result.isError) {
                        this.setState({ isLoading: false, items: result.items }, () => {
                            this.setDropdownOptions();
                        });
                    } else {
                        this.setState({
                            isLoading: false,
                            isError: true,
                            errorMessage: _.get(result, 'errorMessage') || 'Something went wrong',
                        });
                    }
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    this.setState({
                        isLoading: false,
                        isError: true,
                        errorMessage: error.message,
                    });
                }
            });
    };

    setDropdownOptions = () => {
        const { items } = this.state;
        const options = _.map(items, i => {
            return { key: i.guid, value: i.value, text: i.value };
        });
        this.setState({ options: options });
    };

    //handleOnSelectionChanged = (e, data) => {
    //    const { multiple, onSave } = this.props;
    //    console.log()
    //    if (multiple) {
    //        this.setState({ selectedItems: data.value });
    //    } else {
    //        this.setState({ selectedItem: data.value });
    //    }
    //    onSave(data.id, data.value);
    //}

    render() {
        const { fieldType, readOnly } = this.props;
        switch (_.toLower(fieldType)) {
            case 'input':
                return this.renderPhraseWrapper(false, true, false, !readOnly, this.renderInputField);
            case 'dropdown':
                return this.renderPhraseWrapper(false, true, false, false, this.renderDropdownField);
            case 'paragraph':
                return this.renderPhraseWrapper(true, true, false, false, this.renderParagraphField);
            case 'checkbox':
                return this.renderPhraseWrapper(true, true, false, false, () => this.renderCheckboxField(false));
            case 'toggle':
                return this.renderPhraseWrapper(true, true, false, false, () => this.renderCheckboxField(true));
            case 'header':
                return this.renderPhraseWrapper(true, false, false, false, this.renderHeaderField);
            default:
                return null;
        }
    }

    renderPhraseWrapper = (hasText, hasLabel, hasDescription, hasPlaceholder, renderField) => {
        const { name, modulePhrase, portalPhrase, globalPhrase, phraseGuid, moduleName } = this.props;
        if (modulePhrase) {
            return (
                <ModulePhrase
                    guid={phraseGuid}
                    defaultText={hasText ? name : null}
                    defaultLabel={hasLabel ? name : null}
                    defaultDescription={hasLabel ? name : null}
                    defaultPlaceholder={hasLabel ? name : null}
                    hasText={hasText}
                    hasLabel={hasLabel}
                    hasDescription={hasDescription}
                    hasPlaceholder={hasPlaceholder}
                    moduleName={moduleName}
                >
                    {renderField()}
                </ModulePhrase>
            );
        } else if (portalPhrase) {
            return (
                <PortalPhrase
                    guid={phraseGuid}
                    defaultText={hasText ? name : null}
                    defaultLabel={hasLabel ? name : null}
                    defaultDescription={hasLabel ? name : null}
                    defaultPlaceholder={hasLabel ? name : null}
                    hasText={hasText}
                    hasLabel={hasLabel}
                    hasDescription={hasDescription}
                    hasPlaceholder={hasPlaceholder}
                >
                    {renderField}
                </PortalPhrase>
            );
        } else if (globalPhrase) {
            return (
                <GlobalPhrase
                    guid={phraseGuid}
                    defaultText={hasText ? name : null}
                    defaultLabel={hasLabel ? name : null}
                    defaultDescription={hasLabel ? name : null}
                    defaultPlaceholder={hasLabel ? name : null}
                    hasText={hasText}
                    hasLabel={hasLabel}
                    hasDescription={hasDescription}
                    hasPlaceholder={hasPlaceholder}
                >
                    {renderField}
                </GlobalPhrase>
            );
        }
        return null;
    };

    renderInputField = () => {
        const { guid, value, readOnly, required, maxWidth, onSave, onChange, error, errorMessage, loading, disabled } =
            this.props;
        return (
            <PhraseInput
                name={guid}
                value={value}
                onSave={readOnly ? null : onSave}
                readOnly={readOnly}
                required={required}
                maxWidth={maxWidth}
                error={error}
                errorMessage={errorMessage}
                loading={loading}
                onChange={onChange ? e => onChange(_.get(e, 'target.name'), _.get(e, 'target.value')) : null}
                disabled={disabled}
            />
        );
    };

    renderParagraphField = () => {
        const { guid, value, maxWidth } = this.props;
        return <PhraseParagraph name={guid} text={value} maxWidth={maxWidth} />;
    };

    renderCheckboxField = toggle => {
        const {
            guid,
            radio,
            fitted,
            onChange,
            readOnly,
            slider,
            tabIndex,
            type,
            disabled,
            hasLabel,
            checked,
            loading,
            value,
        } = this.props;
        return (
            <PhraseCheckbox
                name={guid}
                checked={checked}
                radio={radio}
                fitted={fitted}
                label={hasLabel}
                readOnly={readOnly}
                slider={slider}
                tabIndex={tabIndex}
                toggle={toggle}
                type={type}
                onChange={onChange ? (e, data) => onChange(_.get(data, 'name'), _.get(data, 'value')) : null}
                disabled={disabled || loading}
                value={value ? value : 'false'}
            />
        );
    };

    renderDropdownField = () => {
        const { guid, readOnly, required, multiple, maxWidth, onSave, onChange, value, error, errorMessage, loading } =
            this.props;

        return (
            <PhraseDropdown
                name={guid}
                disabled={error || this.state.isError}
                required={required}
                options={this.state.options}
                maxWidth={maxWidth}
                multiple={multiple}
                value={value}
                loading={loading || this.state.isLoading}
                error={error || this.state.isError}
                errorMessage={errorMessage}
                onSave={readOnly ? null : onSave}
                onChange={onChange ? (e, data) => onChange(_.get(data, 'name'), _.get(data, 'value')) : null}
            />
        );
    };

    renderHeaderField = () => {
        return <PhraseHeader as="h4" />;
    };
}

export default FieldWrapperComponent;
