import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { privacyOperations } from './duck';
import PrivacyPolicyComponent from './PrivacyPolicyComponent';

const mapStateToProps = (state, props) => {
    const { privacyPolicy, isLoadingPrivacyPolicy } = state.privacy;
    return { privacyPolicy, isLoadingPrivacyPolicy, ...props };
};

const mapDispatchToProps = dispatch => {
    const requestPrivacyPolicy = () => {
        dispatch(privacyOperations.requestPrivacyPolicy());
    };
    return { requestPrivacyPolicy };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyComponent));
