import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import _ from 'lodash';
import dompurify from 'dompurify';
import { Loader } from '../';
import { Modal, Responsive, Header, Menu, Icon, Image, Message } from 'semantic-ui-react';
import { GlobalPhrase, PhraseHeader } from '../../components';
import 'react-pdf/dist/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const sanitizer = dompurify.sanitize;

class PrivacyPolicyComponent extends React.Component {
    componentDidMount() {
        const { privacyPolicy, isLoadingPrivacyPolicy, requestPrivacyPolicy } = this.props;
        if (!privacyPolicy && !isLoadingPrivacyPolicy) {
            requestPrivacyPolicy();
        }
    }

    onDownloadButtonClick = e => {
        const { privacyPolicy } = this.props;
        const link = document.createElement('a');
        link.href = privacyPolicy.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    onBackButtonClick = e => {
        if (this.props.history.action === 'PUSH') {
            this.props.history.goBack();
        } else {
            this.props.history.replace('/');
        }
    };

    render() {
        return (
            <div className="module-padding">
                <Responsive minWidth={Responsive.onlyTablet.maxWidth} fireOnMount>
                    {this.renderDesktop()}
                </Responsive>
                <Responsive maxWidth={Responsive.onlyTablet.maxWidth} fireOnMount style={{ padding: '1em' }}>
                    {this.renderMobile()}
                </Responsive>
            </div>
        );
    }

    renderMobile = () => {
        return this.renderContent();
    };

    renderDesktop = () => {
        return (
            <div style={{ height: '100vh', backgroundColor: 'var(--primary-color)' }}>
                <Modal open={true} size="large">
                    <Modal.Content>{this.renderContent()}</Modal.Content>
                </Modal>
            </div>
        );
    };

    renderContent = () => {
        const { privacyPolicy, isLoadingPrivacyPolicy } = this.props;
        if (!privacyPolicy || isLoadingPrivacyPolicy) {
            return this.renderLoading();
        }
        return (
            <React.Fragment>
                <div style={{ display: 'flex', width: '100%', marginBottom: '2em' }}>
                    <div style={{ flex: 0 }}>
                        <Icon name="chevron left" size="large" link onClick={this.onBackButtonClick} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <GlobalPhrase defaultText="Personvernavtale" guid={privacyPolicy.titleGuid} hasText>
                            <PhraseHeader style={{ textAlign: 'center' }} />
                        </GlobalPhrase>
                    </div>
                    <div style={{ flex: 0 }}>
                        {privacyPolicy.url && (
                            <Icon name="file pdf outline" size="large" link onClick={this.onDownloadButtonClick} />
                        )}
                    </div>
                </div>

                {privacyPolicy.html ? (
                    <div dangerouslySetInnerHTML={{ __html: sanitizer(privacyPolicy.html) }} />
                ) : (
                    <Message negative>
                        <Message.Header>Privacy policy is missing</Message.Header>
                        <p>Privacy policy is missing</p>
                    </Message>
                )}
            </React.Fragment>
        );
    };

    renderLoading = () => {
        return (
            <div style={{ display: 'flex', height: '100vh' }}>
                <Loader />
            </div>
        );
    };
}

export default PrivacyPolicyComponent;
