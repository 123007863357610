import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

import './loader.css';

const LoaderComponent = () => (
    <div className="loader-container">
        <Segment className="loader-content-container">
            <Dimmer active inverted>
                <Loader inverted />
            </Dimmer>
        </Segment>
    </div>
);

export default LoaderComponent;
