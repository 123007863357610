import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, withRouter } from 'react-router';
import { Modal } from 'semantic-ui-react';
import { reactAI } from 'react-appinsights';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GlobalPhrase, PhraseParagraph, PhraseHeader, PhraseButton } from './app/components';
import Layout from './Layout';
import { ModuleWrapper, Error, Login, Logout, NoAccess, GetFile, PrivacyPolicy } from './app/common';
import CustomProperties from 'react-custom-properties';
import { appOperations } from './duck';
import { authOperations } from './app/common/Auth/duck';
import { ImpersonatedUserProvider } from './app/Contexts/ImpersonatedUserContext';
import 'bootstrap/dist/css/bootstrap.min.css';

var appInsights;
const versionPingTimeInMinutes = 1;
const versionIgnoreDelayInMinutes = 180;

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appInsights: null,
            params: null,
            displayVersionModal: false,
            ignoreNewVersion: false,
        };
    }

    componentDidMount() {
        const {
            hasLoadedAppContext,
            hasLoadedAppContextSimplified,
            fetchAppContext,
            fetchAppContextSimplified,
            config,
            isLoadingConfig,
            fetchPortalConfig,
            isAuthenticated,
            fetchAuthIdent,
            authIdent,
            isLoadingAuthIdent,
            validateAuth,
            isLoggingIn,
            selectedLang,
            hasLoadedGlobalPhrases,
            isLoadingGlobalPhrases,
            fetchGlobalPhrases,
            hasLoadedPortalPhrases,
            isLoadingPortalPhrases,
            fetchPortalPhrases,
        } = this.props;
        const params = new URLSearchParams(this.props.location.search);
        this.setState({
            params: params,
        });

        if (!config && !isLoadingConfig) {
            fetchPortalConfig();
        }

        if (!hasLoadedGlobalPhrases && !isLoadingGlobalPhrases) {
            fetchGlobalPhrases(selectedLang);
        }

        if (!hasLoadedPortalPhrases && !isLoadingPortalPhrases) {
            fetchPortalPhrases(selectedLang);
        }

        if (!authIdent && !isLoadingAuthIdent) {
            fetchAuthIdent('', false);
        }

        if (config && !isLoadingConfig) {
            this.loadManifest();
            this.setFavicon(_.get(config, 'favicon'));
        }

        if (!isAuthenticated && authIdent && !isLoggingIn) {
            validateAuth(authIdent, params.get('code'));
        }
        if (isAuthenticated && !hasLoadedAppContext) {
            fetchAppContext();
        }
        if (isAuthenticated && !hasLoadedAppContextSimplified) {
            fetchAppContextSimplified();
        }

        //this.checkVersion();
        //setInterval(() => {
        //    this.checkVersion();
        //}, versionPingTimeInMinutes * 60000);
    }

    componentDidUpdate(prevProps) {
        const {
            hasLoadedAppContext,
            hasLoadedAppContextSimplified,
            fetchAppContext,
            fetchAppContextSimplified,
            config,
            isLoadingConfig,
            fetchPortalConfig,
            isAuthenticated,
            authIdent,
            isLoggingIn,
            validateAuth,
            selectedLang,
            fetchGlobalPhrases,
            isLoadingGlobalPhrases,
            fetchPortalPhrases,
            isLoadingPortalPhrases,
            version,
            fetchProducerInfo,
        } = this.props;
        const { params, displayVersionModal, ignoreNewVersion } = this.state;
        if (config !== prevProps.config && !isLoadingConfig) {
            if (config) {
                this.setFavicon(_.get(config, 'favicon'));
                this.loadApplicationInsights(config.instrumentationKey);
            } else {
                fetchPortalConfig();
            }
        }
        const cachedVersion = localStorage.getItem('version');
        if (version && version !== cachedVersion) {
            if (cachedVersion && cachedVersion !== version && !displayVersionModal && !ignoreNewVersion) {
                this.setState({ displayVersionModal: true });
            }
            if (!cachedVersion) {
                localStorage.setItem('version', version);
            }
        }
        if (!isAuthenticated && authIdent && !_.isEqual(authIdent, prevProps.authIdent) && !isLoggingIn) {
            validateAuth(authIdent, params.get('code'));
        }

        const wasJustAuthenticated = isAuthenticated && isAuthenticated !== prevProps.isAuthenticated;
        if (wasJustAuthenticated && !hasLoadedAppContext) {
            fetchAppContext();
        }
        if (wasJustAuthenticated && !hasLoadedAppContextSimplified) {
            fetchAppContextSimplified();
        }
        if (selectedLang !== prevProps.selectedLang || isAuthenticated !== prevProps.isAuthenticated) {
            if (!isLoadingPortalPhrases) {
                fetchPortalPhrases(selectedLang);
            }
            if (!isLoadingGlobalPhrases) {
                fetchGlobalPhrases(selectedLang);
            }
        }

        if (wasJustAuthenticated) {
            fetchProducerInfo();
        }
    }

    checkVersion = () => {
        const { isLoadingVersion, fetchVersion } = this.props;
        const { displayVersionModal, ignoreNewVersion } = this.state;
        if (!isLoadingVersion && !displayVersionModal && !ignoreNewVersion) {
            fetchVersion();
        }
    };

    setFavicon(src) {
        // Source https://www.jerriepelser.com/blog/change-favicon-application-state/
        if (src) {
            var link = document.createElement('link'),
                oldLink = document.getElementById('dynamic-favicon');
            link.id = 'dynamic-favicon';
            link.rel = 'shortcut icon';
            link.href = src;
            if (oldLink) {
                document.head.removeChild(oldLink);
            }
            document.head.appendChild(link);
        }
    }

    loadApplicationInsights(instrumentationKey) {
        if (!instrumentationKey) return;
        const { history } = this.props;
        if (!appInsights) {
            appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: instrumentationKey,
                    extensions: [reactAI],
                    extensionConfig: {
                        [reactAI.extensionId]: { history: history },
                    },
                },
            });
            appInsights.loadAppInsights();
        }
    }

    handleRefreshApp = () => {
        const { version } = this.props;
        localStorage.setItem('version', version);
        window.location.reload(true);
    };

    handleIgnoreVersion = () => {
        this.setState({ ignoreNewVersion: true, displayVersionModal: false });
        setInterval(() => {
            this.setState({ ignoreNewVersion: false });
        }, versionIgnoreDelayInMinutes * 60000);
    };

    render() {
        const { isUnauthorized, config } = this.props;
        const theme = _.get(config, 'theme') || '';
        const { displayVersionModal } = this.state;
        return (
            <ImpersonatedUserProvider>
                <CustomProperties properties={theme ? JSON.parse(theme) : {}}>
                    <Switch>
                        <Route exact path="/Signin" component={Login}/>
                        <Route exact path="/Signout" component={Logout}/>
                        <Route exact path="/Error" component={Error}/>
                        <Route exact path="/NoAccess" component={NoAccess}/>
                        <Route exact path="/Privacy" component={PrivacyPolicy}/>
                        <PrivateRoute
                            exact
                            path="/Storage/GetFile/:fileId"
                            component={GetFile}
                            isUnauthorized={isUnauthorized}
                            location={this.props.location}
                        />
                        <PrivateRoute
                            exact
                            path="/:moduleName?/:subModuleName?/:foo*"
                            component={ModuleWrapper}
                            layout={Layout}
                            isUnauthorized={isUnauthorized}
                            location={this.props.location}
                        />
                    </Switch>
                    {displayVersionModal && this.renderVersionModal()}
                </CustomProperties>
            </ImpersonatedUserProvider>
        );
    }

    renderVersionModal = () => {
        return (
            <Modal defaultOpen={true}>
                <Modal.Header>
                    <GlobalPhrase defaultText="New version" guid="95dbe3a2-1a1b-40d0-bc1b-85c742282c05" hasText>
                        <PhraseHeader/>
                    </GlobalPhrase>
                </Modal.Header>
                <Modal.Content>
                    <GlobalPhrase
                        defaultText="There's a new version available. Do you wish to update now?"
                        guid="fe1ac92b-4589-49f4-95e1-fa7e17070cd4"
                        hasText
                    >
                        <PhraseParagraph/>
                    </GlobalPhrase>
                </Modal.Content>
                <Modal.Actions
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <GlobalPhrase defaultText="Yes" guid="90d217f7-b5bf-4226-9ca3-740d79eede37" hasText>
                        <PhraseButton
                            style={{
                                backgroundColor: 'var(--success-color)',
                                marginBottom: '1em',
                            }}
                            onClick={this.handleRefreshApp}
                        />
                    </GlobalPhrase>
                    <GlobalPhrase defaultText="Later" guid="98a4e105-ba2e-4f77-9c7b-99de32d937e6" hasText>
                        <PhraseButton
                            style={{
                                backgroundColor: 'var(--material-gray-3)',
                                marginBottom: '1em',
                            }}
                            onClick={this.handleIgnoreVersion}
                        />
                    </GlobalPhrase>
                </Modal.Actions>
            </Modal>
        );
    };
}

function PrivateRoute({ layout, component, isUnauthorized, location, ...rest }) {
    if (isUnauthorized) {
        return (
            <Redirect
                to={{
                    pathname: '/signin',
                    state: {
                        returnUrl: location.pathname,
                        search: location.search,
                    },
                }}
            />
        );
    }
    if (layout) {
        return (
            <Route
                {...rest}
                render={props => React.createElement(layout, props, React.createElement(component, props))}
            />
        );
    }
    return <Route {...rest} render={props => React.createElement(component, props)}/>;
}

const mapStateToProps = state => {
    const {
        version,
        isLoadingVersion,
        isError,
        errorRedirectUri,
        config,
        hasLoadedAppContext,
        hasLoadedAppContextSimplified,
        isLoading,
        selectedLang,
        isLoadingGlobalPhrases,
        isLoadingPortalPhrases,
    } = state.app;
    const { isAuthenticated, isUnauthorized, authIdent, isLoadingAuthIdent, isLoggingIn } = state.auth;
    return {
        version,
        isLoadingVersion,
        isError,
        errorRedirectUri,
        hasLoadedAppContext,
        hasLoadedAppContextSimplified,
        isAuthenticated,
        isUnauthorized,
        authIdent,
        isLoadingAuthIdent,
        isLoggingIn,
        config,
        isLoading,
        selectedLang,
        isLoadingGlobalPhrases,
        isLoadingPortalPhrases,
    };
};

const mapDispatchToProps = dispatch => {
    const fetchAppContext = () => {
        dispatch(appOperations.fetchAppContext());
    };
    const fetchAppContextSimplified = () => {
        dispatch(appOperations.fetchAppContextSimplified());
    };
    const fetchVersion = () => {
        dispatch(appOperations.fetchVersion());
    };
    const fetchPortalConfig = () => {
        dispatch(appOperations.fetchPortalConfig());
    };
    const fetchAuthIdent = (ident, isPersistent) => {
        return dispatch(authOperations.fetchAuthIdent(ident, isPersistent));
    };
    const validateAuth = (authIdent, code) => {
        dispatch(authOperations.doValidateAuth(authIdent, code));
    };
    const fetchGlobalPhrases = lang => {
        dispatch(appOperations.fetchGlobalPhrases(lang));
    };
    const fetchPortalPhrases = lang => {
        dispatch(appOperations.fetchPortalPhrases(lang));
    };

    const fetchProducerInfo = moduleIdent => {
        dispatch(appOperations.fetchProducerInfo(moduleIdent));
    }
    return {
        fetchAppContext,
        fetchAppContextSimplified,
        fetchVersion,
        fetchPortalConfig,
        fetchAuthIdent,
        validateAuth,
        fetchGlobalPhrases,
        fetchPortalPhrases,
        fetchProducerInfo
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
