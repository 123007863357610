import React from 'react';
import _ from 'lodash';
import { Menu, Icon } from 'semantic-ui-react';
import './components.css';
import { PortalPhrase, PhraseMenuItem } from '../../../components/';

class ModuleMenuItem extends React.Component {
    render() {
        const { item } = this.props;
        if (_.isEmpty(item.children)) {
            return this.renderSingleItem();
        } else {
            return this.renderMultiItem();
        }
    }

    renderSingleItem = () => {
        const { item, subModuleName } = this.props;
        return (
            <PortalPhrase defaultText={item.name} guid={item.phraseGuid} hasText>
                <PhraseMenuItem
                    active={subModuleName === item.value}
                    onClick={() => this.props.handleItemClick(item)}
                    className="module-menu-item"
                ></PhraseMenuItem>
            </PortalPhrase>
        );
    };

    renderMultiItem = () => {
        const { item, subModuleName } = this.props;
        return (
            <Menu.Item className="module-menu-item" name={item.feature} active={subModuleName === item.value}>
                <Icon name={item.icon} />
                <Menu.Header>{item.text}</Menu.Header>
                <Menu.Menu>
                    {item.children.map((child, i) => (
                        <Menu.Item key={i} name={child.text} onClick={() => this.handleItemClick(child)} />
                    ))}
                </Menu.Menu>
            </Menu.Item>
        );
    };
}

export default ModuleMenuItem;
