// @app/modules/duck/operations.jsx
import _ from 'lodash';
import { getFile } from './api';

const doGetFile = (moduleIdent, fileId, isDownload) => {
    return dispatch => {
        return getFile(moduleIdent, fileId, isDownload);
    };
};

export default { doGetFile };
