import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    appHandleMessage: ['messageText', 'messageType', 'messageTitle'],
    requestRegistrations: [],
    receiveRegistrations: ['registrations'],
    requestMoreRegistrations: [],
    receiveMoreRegistrations: ['moreRegistrations'],
    addRegistration: ['registration'],
    removeRegistration: ['index'],
    requestProducerInfo: [],
    receiveProducerInfo: ['producerInfo'],
    requestSaveRegistrations: ['registrations'],
    receiveSaveRegistrations: ['savedRegistrations'],
    requestLivestock: {},
    receiveLivestock: ['livestock'],
    requestMoreLivestock: {},
    receiveMoreLivestock: ['moreLivestock'],
    requestSlaughterData: {},
    receiveSlaughterData: ['slaughterData'],
    requestMoreSlaughterData: {},
    receiveMoreSlaughterData: ['moreSlaughterData'],
    requestSlaughterDataReport: {},
    receiveSlaughterDataReport: ['slaughterDataReport'],
    requestMoreSlaughterDataReport: {},
    receiveMoreSlaughterDataReport: ['moreSlaughterDataReport'],
});

export { Creators, Types };
